import React, {useEffect, useState} from "react";
import {Outlet, RouteObject, useNavigate} from "react-router";
import {makeStyles} from '@mui/styles';
import MenuBar from "./layout/MenuBar"
import {LicenseInfo} from '@mui/x-data-grid-pro';
import {ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, useQuery} from "@apollo/client";
import {getAccessToken, logout, setAccessToken} from "./auth/Auth";
import {Box, Grid} from "@mui/material";
import Login from "./auth/Login";
import {onError} from "@apollo/client/link/error";
// import Logo from "./image/logo.png";
import theme from "./theme/Theme";
import ArticleDetail from "./article/ArticleDetail";
import ArticlePage from "./article/ArticlePage";
import AdvertisementPage from "./advertisement/AdvertisementPage";
import AdvertisementDetail from "./advertisement/AdvertisementDetail";
import VideoPage from "./video/VideoPage";
import VideoDetail from "./video/VideoDetail";
import RolePage from "./role/RolePage";
import RoleDetail from "./role/RoleDetail";
import BackstageUserPage from "./backstage-user/BackstageUserPage";
import BackstageUserDetail from "./backstage-user/BackstageUserDetail";
import PagePage from "./page/PagePage";
import PageDetail from "./page/PageDetail";
import CategoryPage from "./category/CategoryPage";
import CategoryDetail from "./category/CategoryDetail";
import FilterCategoryPage from "./filter-category/FilterCategoryPage";
import FilterCategoryDetail from "./filter-category/FilterCategoryDetail";
import ClientPage from "./client/ClientPage";
import ClientDetail from "./client/ClientDetail";
import UserPage from "./user/UserPage";
import UserDetail from "./user/UserDetail";
import {useRoutes} from "react-router-dom";
import SubPagePage from "./subPage/SubPagePage";
import SubPageDetail from "./subPage/SubPageDetail";
import FilterItemPage from "./filter-item/FilterItemPage";
import FilterItemDetail from "./filter-item/FilterItemDetail";
import HomePage from "./home/HomePage";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: theme.palette.background.default,/**/
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 200,
        },
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    content: {
        flex: "1 1 auto",
        height: "100%",
        overflow: "auto",
    },
}));

LicenseInfo.setLicenseKey("e86494dcf29b37d97d97c79bdc08f337Tz01MTE1OSxFPTE2OTUyNjc3ODY2OTEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const MainLayout = () =>
{
    const navigate = useNavigate();
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [accessToken, setStateAccessToken] = useState(getAccessToken());
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token') as string;
    if (token) {
        if (getAccessToken() != token) {
            setAccessToken(token);
            window.location.href = '/';
        }
    }
    if (accessToken == null) {
        return (
            <div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center'}}>
                <Grid display={"flex"}>
                    {/*<img src={Logo} style={{width: 50, height: 50, marginRight:20}}/>*/}
                    {/*<Login*/}
                    {/*    onSuccess={(accessToken) =>*/}
                    {/*    {*/}
                    {/*        setAccessToken(accessToken);*/}
                    {/*        setStateAccessToken(accessToken);*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <a href={`${process.env.REACT_APP_BACKEND_URI}/auth/google/backstage`}>登入</a>
                </Grid>
            </div>
        );
    }
    const errorLink = onError(({graphQLErrors, networkError}) =>
    {
        if (graphQLErrors)
            graphQLErrors.forEach(({message, locations, path}) =>
            {
                if (message === "Unauthorized") {
                    logout();
                    setStateAccessToken(null);

                }
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                )
            });
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });
    const client = new ApolloClient({
        cache: new InMemoryCache({addTypename: false}),
        link: ApolloLink.from([errorLink, new HttpLink({
            uri: `${process.env.REACT_APP_BACKEND_URI}/graphql`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })]),
    });

    return (
        <ApolloProvider client={client}>
            <div className={classes.root}>
                <MenuBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
                <div className={classes.wrapper}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </div>
        </ApolloProvider>
    );
}
const routes: Partial<RouteObject>[] = [
    {
        path: "/",
        element: <MainLayout/>,
        children: [
            {path: "/", element: <HomePage/>},
            {path: "article", element: <ArticlePage/>},
            {path: "article/:id", element: <ArticleDetail/>},
            {path: "advertisement", element: <AdvertisementPage/>},
            {path: "advertisement/:id", element: <AdvertisementDetail/>},
            {path: "video", element: <VideoPage/>},
            {path: "video/:id", element: <VideoDetail/>},
            {path: "role", element: <RolePage/>},
            {path: "role/:id", element: <RoleDetail/>},
            {path: "user", element: <BackstageUserPage/>},
            {path: "user/:id", element: <BackstageUserDetail/>},
            {path: "page", element: <PagePage/>},
            {path: "page/:id", element: <PageDetail/>},
            {path: "page-subpage/:id", element: <SubPagePage/>},
            {path: "subpage/:id", element: <SubPageDetail/>},
            {path: "category", element: <CategoryPage/>},
            {path: "category/:id", element: <CategoryDetail/>},
            {path: "search", element: <FilterCategoryPage/>},
            {path: "search/:id", element: <FilterCategoryDetail/>},
            {path: "search-item/:id", element: <FilterItemPage/>},
            {path: "search-item-view/:id", element: <FilterItemDetail/>},
            {path: "client", element: <ClientPage/>},
            {path: "client/:id", element: <ClientDetail/>},
            {path: "guest", element: <UserPage/>},
            {path: "guest/:id", element: <UserDetail/>},
        ]
    },
    // {
    //     path: "/front-stage",
    //     element: <App/>,
    //     children:
    //         [
    //             // {element: <LaborContractSignPage/>, path: ":uuid"}
    //         ]
    // },
];

export default routes;
