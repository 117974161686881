export const StatusMap = {
    editing: "編輯中",
    publishing: "已上架",
    rejected: "已退件",
    reviewing: "審稿中",
    scheduling: "排程中",
    unpublish: "已下架",
}

export const StatusList = [
    {value: "editing", label: "編輯中"},
    {value: "publishing", label: "已上架"},
    {value: "rejected", label: "已退件"},
    {value: "reviewing", label: "審稿中"},
    {value: "scheduling", label: "排程中"},
    {value: "unpublish", label: "已下架"},
]
export const ArticleGridLayoutOptions = [
    {value: "one", label: "A版"},
    {value: "two", label: "B版"},
    {value: "five", label: "C版"},
    {value: "eight", label: "D版"},
]
export const ArticleGridLayoutMap = {
    one: "A版",
    two: "B版",
    five: "C版",
    eight: "D版",
}

export const AdvertisementGridLayoutOptions = [
    {value: "one", label: "A版"},
    {value: "two", label: "B版"},
    {value: "three", label: "C版"},
    {value: "four", label: "D版"},
]
export const AdvertisementGridLayoutMap = {
    one: "A版",
    two: "B版",
    three: "C版",
    four: "D版",
}