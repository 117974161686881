import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {OrderList, RoleList} from "../common/TestData";
import {useParams} from "react-router-dom";
import {
    useCreateFilterCategoryMutation,
    useGetFilterCategoryLazyQuery, useRemoveFilterCategoryMutation,
    useUpdateFilterCategoryMutation,
} from "../generated/graphql";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetFilterCategoryLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const [updateFilterCategory] = useUpdateFilterCategoryMutation();
    const [createFilterCategory] = useCreateFilterCategoryMutation();
    const [removeFilterCategory] = useRemoveFilterCategoryMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (filterCategory, { resetForm}) => {
            try {
                if (isNew) {
                    const newFilterCategory = await createFilterCategory({
                        variables: {
                            filterCategory
                        }
                    });
                    NotificationPopup.success(`新增完成`);
                    navigate(`/search/${newFilterCategory.data?.createFilterCategory.id}`);
                    window.location.reload();
                }
                else {
                    await updateFilterCategory({
                        variables: {
                            id: parseInt(id as string),
                            filterCategory: omit(filterCategory, ['id']),
                        },
                        refetchQueries: ["getFilterCategory"],
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`發生問題`);
                console.error(e);
            }
        },[data]
    );
    const columns = [
        {name: "name", label: "分類名稱", value: data?.filterCategory.name},
        {name: "index", label: "排序", type: InputType.select, options: OrderList, value: data?.filterCategory.index},
        {name: "enabled", label: "是否啟用", type: InputType.switch, value: data?.filterCategory.enabled},
    ]
    const onRemove = async () => {
        await removeFilterCategory({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getFilterCategories"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate("/search");
    }

    return (
        <Page title={`${isNew ? "新增" : "編輯"}分類`}>
            {(isNew || data?.filterCategory) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
            />}
        </Page>
    )
}