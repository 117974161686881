import Page from "../common/Page";
import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import {BooleanValueGetter, DataGridLocale, DateValueGetter, ViewColumn} from "../common/Utils";
import {ArticleList, PageList, RoleList, UserList} from "../common/TestData";
import {useState} from "react";
import {AdvertisementGridLayoutMap, ArticleGridLayoutMap, StatusMap} from "../common/Constant";
import {useGetPageQuery, useGetPagesQuery} from "../generated/graphql";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    // {field: "index", headerName: "排序", width: 200},
    {field: "title", headerName: "孫頁面名稱", width: 100},
    {field: "articleGridLayout", headerName: "顯示版型", width: 100, valueGetter: (params:GridValueGetterParams) =>
            ArticleGridLayoutMap[params.value]},
    {field: "advertisementGridLayout", headerName: "廣告版型", width: 100, valueGetter: (params:GridValueGetterParams) =>
            AdvertisementGridLayoutMap[params.value]},
    ViewColumn('/subPage'),
]

export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {data} = useGetPageQuery({
        variables: {id: parseInt(id as string)},
    });
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={data?.page.title}>
        <Button onClick={()=>navigate(`/subpage/new?pageId=${id}`)}>
            新增
        </Button>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data?.page?.subPages ? data?.page?.subPages : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
            />
        </Box>
    </Page>
}