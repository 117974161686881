import {Button, Grid} from "@mui/material";
import {Moon} from "react-feather";
import {useNavigate} from "react-router-dom";

export default ({title, button1, button2=null}: {title: string, button1: any, button2?: any}) => {
    const navigate = useNavigate();
    return <Grid
        alignItems="center"
        justifyContent={"center"}
        display="flex"
        flexDirection="column"
        style={{width: 350, height: 200, border: "1pt solid black"}}>
        <div style={{ flex: "1 0 0" }} />
        <Moon/>
        <div>
            <Grid item style={{marginTop: 20}}><b style={{fontSize: 32}}>{title}</b></Grid>
        </div>
        <div style={{ flex: "1 0 0" }} />
        <Grid item container display="flex" flexDirection="column" alignContent="center" justifyContent={"center"} style={{borderTop: "1pt solid black"}}>
            <Button variant={"text"} style={{fontSize: 24, width: "100%", height: 30}} onClick={()=>navigate(button1.url)}>{button1.title}</Button>
        </Grid>
        {button2 && <Grid item container display="flex" flexDirection="column" alignContent="center" justifyContent={"center"} style={{borderTop: "1pt solid black"}}>
            <Button variant={"text"} style={{fontSize: 24, width: "100%", height: 30}} onClick={()=>navigate(button2.url)}>{button2.title}</Button>
        </Grid>}
    </Grid>
}