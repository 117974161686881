import {
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import theme from "../theme/Theme";

export default ({ children, onConfirm, style={}, message, disabled=false, buttonProps={}}:
{children:any, onConfirm: any, style?: {}, message?: string|undefined, disabled?: boolean, buttonProps?: any}) => {
    const [open, setOpen] = useState(false);

    return (
        <div style={style}>
            <Dialog open={open} fullWidth={true}>
                <DialogContent style={{backgroundColor: theme.palette.primary.dark}}>
                    <Typography variant="h4" align="center">
                        {message ? message : `確定要${children}嗎？`}
                    </Typography>
                </DialogContent>
                <DialogActions style={{justifyContent: "center"}}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            setOpen(false);
                            onConfirm();
                        }}
                    >
                        確定
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => setOpen(false)}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => setOpen(true)}
                disabled={disabled}
                {...buttonProps}
            >
                {children}
            </Button>
        </div>
    );
};
