import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {CategoryList, CustomerList, OrderList, PageSelectList} from "../common/TestData";
import {useParams} from "react-router-dom";
import {
    useCreateRoleMutation,
    useGetRoleLazyQuery,
    useRemoveRoleMutation,
    useUpdateRoleMutation
} from "../generated/graphql";
import {useCategories, usePages} from "../common/Utils";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetRoleLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const [updateRole] = useUpdateRoleMutation();
    const [createRole] = useCreateRoleMutation();
    const [removeRole] = useRemoveRoleMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (role, { resetForm}) => {
            try {
                if (isNew) {
                    const newRole = await createRole({
                        variables: {
                            role
                        }
                    });
                    NotificationPopup.success(`新增完成`);
                    navigate(`/role/${newRole.data?.createRole.id}`);
                    window.location.reload();
                }
                else {
                    await updateRole({
                        variables: {
                            id: parseInt(id as string),
                            role: omit(role, ['id']),
                        },
                        refetchQueries: ["getRole"],
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`發生問題`);
                console.error(e);
            }
        },[data]
    );
    const onRemove = async () => {
        await removeRole({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getRoles"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate("/role");
    }
    const columns = [
        {name: "name", label: "角色名稱", value: data?.role.name},
        {name: "enabled", label: "是否啟用", type: InputType.switch, value: data?.role.enabled},
        {name: "permission", label: "權限管理", type: InputType.switch, value: data?.role.permission},
        {name: "page", label: "頁面管理", type: InputType.switch, value: data?.role.page},
        {name: "client", label: "客戶資料管理", type: InputType.switch, value: data?.role.client},
        {name: "category", label: "類別管理", type: InputType.switch, value: data?.role.category},
        {name: "tag", label: "關鍵字管理", type: InputType.switch, value: data?.role.tag},
        {name: "uploadArticle", label: "文章上傳", type: InputType.switch, value: data?.role.uploadArticle},
        {name: "uploadAdvertisement", label: "廣告上傳", type: InputType.switch, value: data?.role.uploadAdvertisement},
        {name: "uploadVideo", label: "影音上傳", type: InputType.switch, value: data?.role.uploadVideo},
        {name: "publishArticle", label: "發布稿件", type: InputType.switch, value: data?.role.publishArticle},
        {name: "publishAdvertisement", label: "發布廣告", type: InputType.switch, value: data?.role.publishAdvertisement},
        {name: "publishVideo", label: "發布影音", type: InputType.switch, value: data?.role.publishVideo},
    ]

    return (
        <Page title={`${isNew ? "新增" : "編輯"}角色`}>
            {(isNew || data?.role) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
            />}
        </Page>
    )
}