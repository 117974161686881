
export const CategoryList = [
    {
        id: 1,
        name: "重型機車",
        status: "active",
    },
]

export const OrderList = [
    {value: 0, label: "1"},
    {value: 1, label: "2"},
    {value: 2, label: "3"},
    {value: 3, label: "4"},
    {value: 4, label: "5"},
    {value: 5, label: "6"},
    {value: 6, label: "7"},
    {value: 7, label: "8"},
]

export const ArticleList = [
    {
        id: 1,
        status: "draft",
        title: "YAMAHA發表Vivo 125",
        page: {title: "新車報導", subpage: {title: "市售輕騎"}},
        owner: {name: "motoworld"},
        startDate: new Date("2022-12-17"),
        endDate: new Date("2022-12-31"),
        content: "Helloblablablablablabla",
        views: 310,
    },
]

export const AdvertisementList = [
    {
        id: 1,
        status: "draft",
        owner: {name: "motoworld"},
        image: {name: "Vinoora 125復古速可達"},
        page: {title: "新車報導", subpage: {title: "市售輕騎"}},
        startDate: new Date("2022-12-17"),
        endDate: new Date("2022-12-31"),
    }
]

export const CustomerList = [
    {
        id: 1,
        name: "A客戶",
        type: "合作廠牌",
        phone: "02-28825252",
        contactName: "王大明",
        contactPhone: "0912231321",
        contactEmail: "moto@moto.world",
    }
]
export const VideoList = [
    {
        id: 1,
        status: "draft",
        owner: {name: "motoworld"},
        title: "Vinoora 125復古速可達",
        page: {title: "新車報導", subpage: {title: "市售輕騎"}},
        startDate: new Date("2022-12-17"),
        endDate: new Date("2022-12-31"),
    }
]

export const RoleList = [
    {
        id: 1,
        name: "網站管理員",
        admin: true,
        page: true,
        customer: true,
        category: false,
        keyword: false,
        articleUpload: true,
        advertisementUpload: true,
        videoUpload: false,
        articlePublish: false,
        advertisementPublish: true,
        videoPublish: true,
    }
]

export const UserList = [
    {
        id: 1,
        username: "motoworld@gmail.com",
        phone: "0912345435",
        role: {name: "編輯記者"},
        status: "active",
    }
]

export const PageList = [
    {
        id: 1,
        order: 1,
        title: "新車報導",
        type: {id: 1, name: "A版"},
        adType: {id: 1, name: "C版"},
        status: "active",
    }
]

export const PageSelectList = PageList.map((page)=>({value: page.id, label: page.title}));

export const PageTypeList = [
    { value: 1, label: "A版"},
    { value: 2, label: "B版"},
    { value: 3, label: "C版"},
]

export const GuestList = [
    {
        id: 1,
        username: "motoworld@gmail.com",
        status: "active",
    }
]