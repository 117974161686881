import {Field} from "formik";
import React from "react";
import {chunk, find} from "lodash";
import {
    FormControl,
    FormGroup,
    Grid,
    TextField,
    GridSize,
    MenuItem,
    Select,
    InputLabel,
    Input,
    Chip,
    Button,
    Checkbox,
    FormControlLabel, Switch,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import NumberFormat from "react-number-format";
import {format} from "date-fns";
import UploadFile from "./UploadFile";
import JoditEditor from 'jodit-react';

export enum InputType {
    textarea = "textarea",
    number = "formatednumber",
    text = "text",
    select = "select",
    email = "email",
    date = "date",
    datetime = "datetime-local",
    month = "month",
    hidden = "hidden",
    autoComplete = "autocomplete",
    button = "button",
    custom = "custom",
    checkbox = "checkbox",
    switch = "switch",
    freenumber = "number",
    file = "file",
    html = "html",
}
interface FieldDef {
    required?: boolean;
    name: string;
    type?: InputType;
    label?: string;
    options?: any[];
    isMulti?: boolean;
    form?: any;
    component?: any;
    renderCell:any;
    RenderCell:any;
    freeSolo?: boolean;
    button?: any;
}
type OnChangeFn = (name: string, value: any) => void;

interface Params {
    def: FieldDef[];
    values: any;
    onChange: OnChangeFn;
    columns?: number; //number of columns
}
const style = {
    marginTop: 10,
};

const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { options } = event.target as HTMLSelectElement;
    const value = [];
    // for (let i = 0, l = options.length; i < l; i += 1) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    // }
};

export interface SelectFieldParams
{
    options: { value:any,label:string }[];
    field:any;
    form:any;
    disabled?:boolean;
    required?:boolean;
    isMulti?:boolean;
    label?:string;
}

export const SelectField = ({
                                options,
                                field,
                                form,
                                disabled,
                                required,
                                isMulti,
                                label,
                            }:SelectFieldParams) => {
    return (
        <FormControl required={required}>
            {/*<InputLabel>{label}</InputLabel>*/}
            <Select
                // label={label}
                disabled={disabled}
                multiple={isMulti}
                value={field.value}
                name={field.name}
                onChange={(event: any) => {
                    form.setFieldValue(field.name, event.target.value);
                    event.stopPropagation();
                }}
            >
                {options?.map(({ value, label }) => {
                    return (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
export const AutoCompleteField = ({
                                      options,
                                      field,
                                      form,
                                      disabled,
                                      required,
                                      isMulti,
                                      label,
                                      freeSolo,
                                      value,
                                  }) => {
    return (
        <Autocomplete<string, boolean, boolean, boolean>
            multiple={isMulti}
            freeSolo={freeSolo}
            options={options ? options : []}
            getOptionLabel={(option: any) => {
                return option.label;
            }}
            defaultValue={find(options, (option)=>value==option.value)}
            renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField {...params} />
            )}
            onChange={(_, option: any) => {
                if (option instanceof Array) {
                    form.setFieldValue(field.name, option);
                } else if (typeof option === "object") {
                    form.setFieldValue(field.name, option?.value);
                } else {
                    form.setFieldValue(field.name, option);
                }
            }}
        />
    );
};
interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: number } }) => void;
    name: string;
}
export function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: parseInt(values.value),
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
const Number = ({ field, form, ...props }) => {
    return (
        <TextField
            {...field}
            {...props}
            label={null}
            name={field.name}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
            }}
        />
    );
};

const Text = ({ field, form, pattern, ...props }) => {
    return <TextField {...field} {...props} label={null} name={field.name} inputProps={{ pattern: pattern }} />;
};

const FormButton = ({ field, label, hidden, ...props }) => {
    return hidden ? (
        <Button
            variant={"contained"}
            color={"secondary"}
            {...field}
            {...props}
            style={{ width: "fit-content", marginTop: 10 }}
        >
            {" "}
            {label}{" "}
        </Button>
    ) : (
        <></>
    );
};
const CustomView = ({ renderCell,RenderCell,form,field }) => {
    if(RenderCell)
    {
        return <RenderCell form={form} field={field}/>;
    }
    else {
        return renderCell;
    }

};

const Check = ({ field, form, label, ...props }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...field}
                    {...props}
                    defaultChecked={field.value}
                    name={field.name}
                    onChange={(event: any) => {
                        form.setFieldValue(field.name, event.target.checked);
                    }}
                />
            }
            label={label}
        />
    );
};
const SwitchButton = ({ field, form, disabled, pattern, ...props }) => {
    return <Switch
        {...field}
        name={field.name}
        defaultChecked={field.value}
        disabled={disabled}
        onChange={(event: any) => {
            form.setFieldValue(field.name, event.target.checked);
        }}
    />
};
const UploadButton = ({ field, form, pattern, ...props }) => {
    return <div>
        { field.value ?
            <img style={{maxWidth: "100%"}} src={`${process.env.REACT_APP_BACKEND_URI}/files/${field.value}`}/>
            : null}
        <UploadFile
            onSuccess={(res) => {
                form.setFieldValue(field.name, res.id);
            }}
            uploadTitle={"選擇檔案"}
        />
    </div>
}
const HtmlEditor = ({ field, form, pattern, ...props }) => {
    return <JoditEditor
        {...field}
        value={field.value}
        onChange={(newValue)=>{
            form?.setFieldValue(field.name, newValue);
        }}
    />
}

const inputRender = (fieldDef: FieldDef, onChange, value) => {
    switch (fieldDef.type) {
        case InputType.select:
            return (
                <Field
                    component={SelectField}
                    {...fieldDef}
                    onChange={onChange}
                    value={value}
                />
            );
        case InputType.textarea:
            return (
                <Field
                    component={Text}
                    {...fieldDef}
                    value={value}
                    onChange={onChange}
                    multiline
                    style={style}
                />
            );
        case InputType.autoComplete:
            return (
                <Field
                    component={AutoCompleteField}
                    {...fieldDef}
                    onChange={onChange}
                    value={value}
                />
            );
        case InputType.hidden:
            return <div></div>;
        case InputType.button:
            return (
                <Field
                    component={FormButton}
                    {...fieldDef}
                    onChange={onChange}
                />
            );
        case InputType.custom:
            return (
                <Field
                    component={CustomView}
                    {...fieldDef}
                    onChange={onChange}
                />
            );
        case InputType.checkbox:
            return (
                <Field
                    component={Check}
                    label={fieldDef.label}
                    name={fieldDef.name}
                    onChange={onChange}
                />
            );
        case InputType.number:
            return (
                <Field
                    component={Number}
                    {...fieldDef}
                    value={value}
                    onChange={onChange}
                />
            );
        case InputType.datetime:
            return (
                <Field
                    component={Text}
                    {...fieldDef}
                    value={value ? format(new Date(value), "yyyy-MM-dd HH:mm") : ""}
                    onChange={onChange}
                    InputProps={{startAdornment: <></>}}
                />
            )
        case InputType.date:
            return <Field
                component={Text}
                {...fieldDef}
                value={value}
                onChange={onChange}
                InputProps={{startAdornment: <></>, inputProps: {min:"0000-01-01",max: "2999-12-31"}}}
            />
        case InputType.month:
            return <Field
                component={Text}
                {...fieldDef}
                value={value}
                onChange={onChange}
                InputProps={{startAdornment: <></>, inputProps: {min:"0000-01",max: "2999-12"}}}
            />
        case InputType.switch:
            return <Field
                component={SwitchButton}
                {...fieldDef}
                value={value}
                onChange={onChange}
            />
        case InputType.file:
            return <Field
                component={UploadButton}
                {...fieldDef}
                value={value}
                onChange={onChange}
            />
        case InputType.html:
            return <Field
                component={HtmlEditor}
                {...fieldDef}
                value={value}
                onChange={onChange}
            />
        case InputType.text:
        case InputType.email:
        case InputType.freenumber:

        default:
            return (
                <Field
                    component={Text}
                    {...fieldDef}
                    value={value}
                    onChange={onChange}
                />
            );
    }
};

const formPart = (def: FieldDef[], onChange, values) => {
    return def.map((fieldDef) => {
        return (
            <FormGroup key={fieldDef.name}>
                {/*<FormLabel>{fieldDef.label || labelize(fieldDef.name)} {fieldDef.required && "*"} </FormLabel>*/}
                <FormControl style={{maxWidth: "100%"}}>
                    {inputRender(fieldDef, onChange, values[fieldDef.name])}
                </FormControl>
            </FormGroup>
        );
    });
};

const formContent = (columns, def, onChange, values) => {
    if (columns) {
        const chunked = chunk(def, columns);
        return chunked.map((chunk:any[], index) => (
            <Grid container key={index}>
                {chunk.map((col, index) => (
                    <Grid container item key={index} md={(12 / columns) as GridSize} style={{marginTop: 10}}>
                        <Grid item md={2} style={{alignItems: "center", display: "flex"}}>
                            {col.label}
                        </Grid>
                        <Grid item md={8}>
                            {formPart([col], onChange, values)}
                        </Grid>
                        <Grid item md={2} style={{alignItems: "center", display: "flex"}}>
                            {col.button}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        ));
    } else {
        return formPart(def, onChange, values);
    }
};

export default ({ def, onChange, columns = 1, values }: Params) => {
    //TODO: add YUP validation?
    return <>{formContent(columns, def, onChange, values)}</>
};
