import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {selectMapFn, useCategories, useMe, usePages} from "../common/Utils";
import {useParams} from "react-router-dom";
import {
    Status,
    useCreateArticleMutation,
    useGetArticleLazyQuery,
    useRemoveArticleMutation,
    useUpdateArticleMutation
} from "../generated/graphql";
import {useCallback, useEffect, useState} from "react";
import {format} from "date-fns";
import NotificationPopup from "../common/NotificationPopup";
import {find, omit} from "lodash";
import {FormControl, Grid, MenuItem, Select} from "@mui/material";
import {StatusList} from "../common/Constant";
import {useNavigate} from "react-router";
import {OrderList} from "../common/TestData";

export const pageColumn = (pages, subPageId, pageId?) => {
    let initPage:any = null;
    if (subPageId) {
        initPage = find(pages, (page)=>find(page.subPages, (subPage)=>subPage.id == subPageId));
    }
    else if (pageId) {
        initPage = find(pages, (page)=>page.id == pageId);
    }

    return {
        name: "subPageId",
        label: "所屬子頁面",
        type: InputType.custom,
        RenderCell: ({form, field}) => {
            const [page, setPage] = useState(initPage);
            return <Grid container spacing={0}>
                <Grid item xs={4}>
                    <FormControl style={{width: "100%"}}>
                        <Select defaultValue={page?.id} onChange={(event)=>{
                            form.setFieldValue("pageId", parseInt(event.target.value));
                            form.setFieldValue("subPageId", null);
                            setPage(find(pages, (page)=>page.id==event.target.value));
                        }}>
                            {pages?.map((page)=>{
                                return (
                                    <MenuItem value={page.id} key={page.id}>
                                        {page.title}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item md={2} style={{marginLeft: 20, alignItems: "center", display: "flex"}}>
                    所屬孫頁面
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{width: "100%"}}>
                        <Select defaultValue={subPageId} onChange={(event)=>{
                            form.setFieldValue("subPageId", parseInt(event.target.value))
                        }}>
                            {page?.subPages?.map((subPage)=>{
                                return (
                                    <MenuItem value={subPage.id} key={subPage.id}>
                                        {subPage.title}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        }
    }
}

export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetArticleLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const me = useMe();
    const categories = useCategories();
    const pages = usePages();
    const [updateArticle] = useUpdateArticleMutation();
    const [createArticle] = useCreateArticleMutation();
    const [removeArticle] = useRemoveArticleMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (article, { resetForm }) => {
            try {
                if (isNew) {
                    const newArticle = await createArticle({
                        variables: {
                            article: {...omit(article,["pageId"]), authorId: me?.id}
                        }
                    })
                    NotificationPopup.success(`新增完成`);
                    navigate(`/article/${newArticle?.data?.createArticle.id}`);
                    window.location.reload();
                }
                else {
                    await updateArticle({
                        variables: {
                            id: parseInt(id as string),
                            article: omit(article, ['id', 'pageId']),
                        },
                        refetchQueries: ["getArticle"],
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`發生問題`);
                console.error(e);
            }
        },[data, me]
    );
    const onReview = async () => {
        await updateArticle({
            variables: {
                id: parseInt(id as string),
                article: {
                    status: Status.Reviewing,
                }
            },
            refetchQueries: ["getArticle"],
        });
        NotificationPopup.success(`送審成功`);
        window.location.reload();
    }
    const onRemove = async () => {
        await removeArticle({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getArticles"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate("/article");
    }
    const columns = [
        {name: "id", label: "", type: InputType.hidden, value: data?.article?.id},
        {name: "status", label: isNew ? "" : "狀態", type: isNew ? InputType.hidden : InputType.select, options: StatusList, value: data?.article?.status, disabled: !(me?.role?.publishArticle)},
        pageColumn(pages, data?.article?.subPage?.id),
        {name: "title", label: "文章標題", value: data?.article?.title},
        {name: "author", label: "供搞人", disabled: true, value: isNew ? me?.name : data?.article?.author?.name},
        {name: "price", label: "價格", type: InputType.number, value: data?.article?.price},
        {name: "startDate", label: "開始日期", type: InputType.date, value: isNew ? null : format(new Date(data?.article?.startDate ?? null), "yyyy-MM-dd")},
        {name: "endDate", label: "結束日期", type: InputType.date, value: format(isNew ? new Date() : new Date(data?.article?.endDate ?? null), "yyyy-MM-dd")},
        {name: "tags", label: "關鍵字TAG", value: data?.article?.tags},
        {name: "categoryId", label: "類別", type: InputType.select, options: (categories as any)?.map(selectMapFn), value: data?.article?.category?.id},
        {name: "index", label: "優先序", type: InputType.select, options: OrderList, value: data?.article?.index},
        {name: "content", label: "稿件內容", type: InputType.html, value: data?.article?.content},
        {name: "managerNote", label: "備註", value: data?.article?.managerNote},
        {name: "isEditorChoice", label: "編輯精選", type: InputType.switch, value: data?.article?.isEditorChoice},
        {name: "isAcceptedPublish", label: "是否發布", type: InputType.switch, value: data?.article?.isAcceptedPublish, disabled: !(me?.role?.publishArticle)},
        {name: "imageId", label: "封面圖上傳", type: InputType.file, value: data?.article?.imageId},
        {name: "bannerId", label: "頭版圖上傳", type: InputType.file, value: data?.article?.bannerId},
    ];
    return (
        <Page title={`${isNew ? "新增" : "編輯"}稿件`}>
            {((isNew && me && categories) || data?.article) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
                onReview={data?.article?.status==Status.Editing ? onReview : null}
            />}
        </Page>
    )
}