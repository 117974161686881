// import {
//     Authorization,
//     Company,
//     Contractor, IncomeTypeEnum, Maybe, RoleEnum, TaxClassificationEnum,
//     useGetCompaniesQuery,
//     useGetContractorsQuery,
//     useGetMeQuery,
//     User
// } from "../generated/graphql";
import {useEffect, useState} from "react";
// import {useUser} from "../auth/Auth";
import {GridCellParams, GridLocaleText, GridValueGetterParams} from '@mui/x-data-grid-pro';
import {useNavigate, useSearchParams} from "react-router-dom";
import {find, get} from "lodash";
import {add, format, parseISO} from "date-fns";
import {Eye} from "react-feather";
import LinkButton from "../component/LinkButton";
import {
    BackstageUser,
    Category,
    Client,
    Page, Role,
    useGetCategoriesQuery,
    useGetClientsQuery, useGetMeQuery,
    useGetPagesQuery, useGetRolesQuery
} from "../generated/graphql";

export const DataGridLocale:Partial<GridLocaleText> = {
    // booleanCellFalseLabel: "",
    // booleanCellTrueLabel: "",
    // checkboxSelectionHeaderName: "",
    // filterValueAny: "",
    // filterValueFalse: "",
    // filterValueTrue: "",
    // footerRowSelected(count: number): React.ReactNode
    // {
    //     return undefined;
    // },
    // footerTotalRows: undefined,
    // noResultsOverlayLabel: "",
    // toolbarColumns: undefined,
    // toolbarColumnsLabel: "",
    // toolbarDensity: undefined,
    // toolbarDensityComfortable: "",
    // toolbarDensityCompact: "",
    // toolbarDensityLabel: "",
    // toolbarDensityStandard: "",
    toolbarExport: "輸出報表",
    toolbarExportCSV: "下載CSV檔",
    // Root
    // rootGridLabel: "表單",
    noRowsLabel: "沒有任何內容",
    errorOverlayDefaultLabel: "錯誤發生",
    // Column menu text
    columnMenuLabel: "Menu",
    columnMenuShowColumns: "顯示",
    columnMenuFilter: "篩選",
    columnMenuHideColumn: "隱藏",
    columnMenuUnsort: "不指定排序",
    columnMenuSortAsc: "由小到大",
    columnMenuSortDesc: "由大到小",
    // Columns panel text
    columnsPanelTextFieldLabel: "尋找欄位",
    columnsPanelTextFieldPlaceholder: "欄位名稱",
    columnsPanelDragIconLabel: "重新排序欄位",
    columnsPanelShowAllButton: "顯示全部",
    columnsPanelHideAllButton: "隱藏全部",
    // Filter panel text
    filterPanelAddFilter: "增加篩選",
    filterPanelDeleteIconLabel: "刪除",
    filterPanelOperators: "篩選條件",
    filterPanelOperatorAnd: "And",
    filterPanelOperatorOr: "Or",
    filterPanelColumns: "欄位",
    filterPanelInputLabel: "值",
    filterPanelInputPlaceholder: "輸入值",
    // Filter operators text
    filterOperatorContains: "包含",
    filterOperatorEquals: "等於",
    filterOperatorStartsWith: "開頭是",
    filterOperatorEndsWith: "結尾是",
    filterOperatorIs: "等於",
    filterOperatorNot: "不等於",
    filterOperatorIsAnyOf: "屬於任何",
    filterOperatorAfter: "晚於",
    filterOperatorOnOrAfter: "晚於(包含)",
    filterOperatorBefore: "早於",
    filterOperatorOnOrBefore: "早於(包含)",
    filterOperatorIsEmpty: "等於空值",
    filterOperatorIsNotEmpty: "不是空值",

    // Filters toolbar button text
    toolbarFilters: "篩選",
    toolbarFiltersLabel: "顯示篩選",
    toolbarFiltersTooltipHide: "隱藏篩選",
    toolbarFiltersTooltipShow: "顯示篩選",
    toolbarFiltersTooltipActive: (count) => `${count}個篩選條件`,
    // Column header text
    columnHeaderFiltersTooltipActive: (count) => `${count}個篩選條件`,
    columnHeaderFiltersLabel: "顯示篩選",
    columnHeaderSortIconLabel: "排序",
    pinToLeft: "釘選至左邊",
    pinToRight: "釘選至右邊",
    unpin: "取消釘選",

    noResultsOverlayLabel:"沒有資料",
};

export const useTabIndex = (): [number, (n: number) => void] => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setIndex(
            searchParams.get("index")
                ? parseInt(searchParams.get("index") as string)
                : 0
        );
    }, []);
    useEffect(() => {
        navigate({
            search: `?index=${index}`,
        }, {replace:true});
    }, [index])
    return [index, setIndex];
};

export const DefaultValueGetter = (params: GridValueGetterParams) => {
    return get(params.row, params.field);
};

export const DatetimeValueGetter = (params: GridValueGetterParams) => {
    return format(new Date(get(params.row, params.field)), "yyyy-MM-dd HH:mm");
}

export const DateValueGetter = (params: GridValueGetterParams) => {
    // console.log(params.row);
    if (get(params.row, params.field) == null) {
        return '--';
    }
    return format(new Date(get(params.row, params.field)), "yyyy-MM-dd");
}
export const BooleanValueGetter = (params: GridValueGetterParams) => {
    return get(params.row, params.field) ? "Ｏ" : "Ｘ";
}

export const selectMapFn = ({ id, name }: { id: any; name?: any }) => ({
    value: id,
    label: name,
});

export const ViewColumn = (routePrefix?: null | string, headerName = "編輯") => {
    // const navigate = useNavigate();
    return {
        type: "actions",
        headerName: headerName,
        field: headerName,
        width: 90,
        disableColumnMenu: true,
        disableColumnSelector: false,
        sortable: false,
        getActions: (params) => [
            <LinkButton
                uri={
                    routePrefix
                        ? `${routePrefix}/${params.row.id}`
                        : params.row.id
                }
            >
                <Eye/>
            </LinkButton>,
        ]
    };
};

export const useCategories = (): Array<Partial<Category> | undefined> | undefined => {
    const [categories, setCategories] = useState<Array<Partial<Category> | undefined> | undefined>(undefined);
    const {data, error} = useGetCategoriesQuery();
    useEffect(() => {
        if (!error && data) {
            setCategories(data.categories as any);
        }
    }, [data, setCategories, error])

    return categories;
}

export const usePages = (): Array<Partial<Page> | undefined> | undefined => {
    const [pages, setPages] = useState<Array<Partial<Page> | undefined> | undefined>(undefined);
    const {data, error} = useGetPagesQuery();
    useEffect(() => {
        if (!error && data) {
            setPages(data.pages as any);
        }
    }, [data, setPages, error])
    return pages;
}

export const useClients = (): Array<Partial<Client> | undefined> | undefined => {
    const [clients, setClients] = useState<Array<Partial<Client> | undefined> | undefined>(undefined);
    const {data, error} = useGetClientsQuery();
    useEffect(() => {
        if (!error && data) {
            setClients(data.clients as any);
        }
    }, [data, setClients, error])
    return clients;
}
export const useRoles = (): Array<Partial<Role> | undefined> | undefined => {
    const [roles, setRoles] = useState<Array<Partial<Role> | undefined> | undefined>(undefined);
    const {data, error} = useGetRolesQuery();
    useEffect(() => {
        if (!error && data) {
            setRoles(data.roles as any);
        }
    }, [data, setRoles, error])
    return roles;
}

export const useMe = (): Partial<BackstageUser | undefined> | undefined => {
    const [me, setMe] = useState<Partial<BackstageUser> | undefined>(undefined);
    const {data, error} = useGetMeQuery();
    useEffect(() => {
        if (!error && data) {
            setMe(data.me as any);
        }
    }, [data, setMe, error])
    return me;
}

export const HasAuth = ({authField, children})=>
{
    const user = useMe();
    if (authField == undefined) {
        return children;
    }
    else if ( get(user?.role, authField) )
    {
        return children;
    }
    else
    {
        return null;
    }
}