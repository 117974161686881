import Page from "../common/Page";
import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import {BooleanValueGetter, DataGridLocale, DateValueGetter, ViewColumn} from "../common/Utils";
import {ArticleList, CategoryList, RoleList, UserList} from "../common/TestData";
import {useState} from "react";
import {StatusMap} from "../common/Constant";
import {useGetFilterCategoriesQuery, useGetFilterCategoryQuery, useGetPageQuery} from "../generated/graphql";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    {field: "index", headerName: "排序", width: 100},
    {field: "name", headerName: "子分類名稱", width: 200},
    {field: "enabled", headerName: "狀態", width: 100, valueGetter: (params:GridValueGetterParams) =>
            params.value ? "啟用中" : "停用中"},
    ViewColumn('/search-item-view'),

]
export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {data} = useGetFilterCategoryQuery({
        variables: {id: parseInt(id as string)},
    });
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={`分類名稱：${data?.filterCategory.name}`}>
        <Button onClick={()=>navigate(`/search-item-view/new?searchId=${id}`)}>
            新增
        </Button>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data?.filterCategory?.filterItems ? data?.filterCategory?.filterItems : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
            />
        </Box>
    </Page>
}