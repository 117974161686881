import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {PageTypeList, RoleList} from "../common/TestData";
import {useParams} from "react-router-dom";
import {
    useCreateClientMutation,
    useGetClientLazyQuery,
    useGetVideoLazyQuery, useRemoveClientMutation,
    useUpdateClientMutation,
    useUpdateVideoMutation
} from "../generated/graphql";
import {useCategories, usePages} from "../common/Utils";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetClientLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const [updateClient] = useUpdateClientMutation();
    const [createClient] = useCreateClientMutation();
    const [removeClient] = useRemoveClientMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (client, { resetForm}) => {
            try {
                if (isNew) {
                    const newClient = await createClient({
                        variables: {
                            client
                        }
                    });
                    NotificationPopup.success(`新增完成`);
                    navigate(`/client/${newClient.data?.createClient.id}`);
                    window.location.reload();
                }
                else {
                    await updateClient({
                        variables: {
                            id: parseInt(id as string),
                            client: omit(client, ['id']),
                        }
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`修改發生問題`);
                console.error(e);
            }
        },[data]
    );
    const onRemove = async () => {
        await removeClient({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getClients"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate("/client");
    }
    const columns = [
        {name: "name", label: "公司名稱", value: data?.client.name},
        {name: "type", label: "客戶類型", value: data?.client.type},
        {name: "address", label: "公司地址", value: data?.client.address},
        {name: "taxId", label: "公司統編", value: data?.client.taxId},
        {name: "phone", label: "公司電話", value: data?.client.phone},
        {name: "contactName", label: "聯絡人姓名", value: data?.client.contactName},
        {name: "contactTitle", label: "聯絡人頭銜", value: data?.client.contactTitle},
        {name: "contactUnit", label: "聯絡人單位", value: data?.client.contactUnit},
        {name: "contactItem", label: "聯絡人負責項目", value: data?.client.contactItem},
        {name: "contactPhone", label: "聯絡人手機", value: data?.client.contactPhone},
        {name: "contactEmail", label: "聯絡人Email", value: data?.client.contactEmail},
        {name: "note", label: "備註", value: data?.client.note},
    ]

    return (
        <Page title={`${isNew ? "新增" : "編輯"}客戶資料`}>
            {(isNew || data?.client) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
            />}
        </Page>
    )
}