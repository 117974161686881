import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {OrderList} from "../common/TestData";
import {selectMapFn, useCategories, useMe, usePages} from "../common/Utils";
import {StatusList} from "../common/Constant";
import {useParams} from "react-router-dom";
import {
    Status,
    useCreateVideoMutation,
    useGetVideoLazyQuery,
    useRemoveVideoMutation,
    useUpdateVideoMutation
} from "../generated/graphql";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {format} from "date-fns";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetVideoLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const categories = useCategories();
    const pages = usePages();
    const me = useMe();
    const [updateVideo] = useUpdateVideoMutation();
    const [createVideo] = useCreateVideoMutation();
    const [removeVideo] = useRemoveVideoMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (video, { resetForm }) => {
            try {
                if (isNew) {
                    const newVideo = await createVideo({
                        variables: {
                            video: {...video, authorId: me?.id},
                        }
                    });
                    NotificationPopup.success(`新增完成`);
                    await navigate(`/video/${newVideo.data?.createVideo.id}`);
                    window.location.reload();
                }
                else {
                    await updateVideo({
                        variables: {
                            id: parseInt(id as string),
                            video: omit(video, ['id']),
                        },
                        refetchQueries: ["getVideo"],
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`發生問題`);
                console.error(e);
            }
        },[data, me]
    );
    const onReview = async ()=>{
        await updateVideo({
            variables: {
                id: parseInt(id as string),
                video: {
                    status: Status.Reviewing,
                }
            },
            refetchQueries: ["getVideo"],
        });
        NotificationPopup.success(`送審成功`);
        window.location.reload();
    }
    const onRemove = async () => {
        await removeVideo({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getVideos"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate(`/video`);
    }
    const columns = [
        {name: "status", label: isNew ? "" : "狀態", type: isNew ? InputType.hidden : InputType.select, options: StatusList, value: data?.video.status, disabled: !(me?.role?.publishVideo)},
        {name: "pageId", label: "影音刊登頁面", type: InputType.select, options: pages?.map((page)=>({value: page?.id, label: page?.title})), value: data?.video.page?.id},
        {name: "index", label: "影音刊登位置", type: InputType.select, options: OrderList, value: data?.video.index},
        {name: "title", label: "影音名稱", value: data?.video.title},
        {name: "categoryId", label: "類別", type: InputType.select, options: (categories as any)?.map(selectMapFn), value: data?.video?.category?.id},
        {name: "author", label: "供搞人", disabled: true, value: isNew ? me?.name : data?.video?.author?.name},
        {name: "startDate", label: "開始日期", type: InputType.date, value: isNew ? null : format(new Date(data?.video?.startDate ?? null), "yyyy-MM-dd")},
        {name: "endDate", label: "結束日期", type: InputType.date, value: isNew ? null : format(new Date(data?.video?.endDate ?? null), "yyyy-MM-dd")},
        {name: "fileId", label: "影音上傳", type: InputType.file, value: data?.video?.fileId},
        {name: "note", label: "備註", value: data?.video.note},
        {name: "isAcceptedPublish", label: "是否發布", type: InputType.switch, value: data?.video?.isAcceptedPublish, disabled: !(me?.role?.publishVideo)},
    ]

    return (
        <Page title={`${isNew ? "新增" : "編輯"}影片`}>
            {((isNew && me && pages && categories) || data?.video) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
                onReview={data?.video?.status==Status.Editing ? onReview : null}
            />}
        </Page>
    )
}