import { Button, CircularProgress } from "@mui/material";
import NotificationPopup from "./NotificationPopup";
import { getAccessToken } from "../auth/Auth";
import {useEffect, useState} from "react";
import {get, isArray} from "lodash";
import {v4 as uuid} from 'uuid';

export const uploadFile = async (uriPart: string, file: File | Blob, onSuccess) => {
    const token = await getAccessToken();
    const formData = new FormData();
    if(file)
    {
        formData.append("file",file)
    }

    const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}${uriPart}`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        }
    );
    if (res.ok) {
        NotificationPopup.success(`上傳成功`);
        onSuccess(await res.json());
    }
    else
    {
        res.json().then((res) => NotificationPopup.error(`上傳遇到問題. ${res?.message}`)).catch(()=>NotificationPopup.error(`上傳遇到問題.`));
        console.error(
            `failed to upload file. statustext: ${res.statusText} status: ${res.status}`
        );
    }
};

export default ({  onSuccess, uploadTitle, uploadPath="/files/upload" }) => {
    const [file, setFile] = useState<File|undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const id = uuid();
    useEffect(()=>{
        if (file) {
            const upload = async () => {
                setLoading(true);
                await Promise.all([uploadFile(uploadPath, file, onSuccess)]);
                setLoading(false);
                setFile(undefined);
            }
            upload();
        }
    }, [file])
    return (
        <div>
            {!loading && (
                <Button
                    variant={"contained"}
                    color={"primary"}
                    component={"label"}
                    htmlFor={id}
                >
                    {uploadTitle}
                </Button>
            )}
            {loading && <CircularProgress />}
            <br />
            <input
                style={{ display: "none" }}
                id={id}
                type={"file"}
                onChange={(e) => {
                    const files = e.target.files;
                    setFile(get(files, 0));
                }}
            />
        </div>
    );
};
