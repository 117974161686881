import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {DataGridLocale, DateValueGetter, HasAuth, useCategories, usePages, ViewColumn} from "../common/Utils";
import {StatusMap} from "../common/Constant";
import {useEffect, useState} from "react";
import {Box, Button} from "@mui/material";
import {AdvertisementList, ArticleList} from "../common/TestData";
import Page from "../common/Page";
import {
    useGetAdvertisementLazyQuery,
    useGetAdvertisementsQuery,
    useGetArticleLazyQuery,
    useUpdateArticleMutation
} from "../generated/graphql";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    {field: "eventName", headerName: "活動名稱", width: 200},
    // {field: "owner", headerName: "供稿人", width: 100, valueGetter: (params:GridValueGetterParams) =>
    //         params.value.name},
    {field: "subPage", headerName: "所屬頁面", width: 200, valueGetter: (params:GridValueGetterParams) =>
            params.value ? `${params.value ?.page?.title} -> ${params.value?.title}` : `${params.row.page?.title}`},
    {field: "startDate", headerName: "上架日期", width: 100, valueGetter: DateValueGetter},
    {field: "endDate", headerName: "下架日期", width: 100, valueGetter: DateValueGetter},
    {field: "price", headerName: "表定價格", width: 100},
    {field: "discountPercent", headerName: "實收價格", width: 100, valueGetter: (params:GridValueGetterParams) =>
        params.row.price * params.value},
    {field: "commissionPercent", headerName: "佣金", width: 100, valueGetter: (params:GridValueGetterParams) =>
            params.row.price * params.row.discountPercent * params.value},
    ViewColumn('/advertisement'),
]

export default () => {
    const navigate = useNavigate();
    const {data} = useGetAdvertisementsQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={"廣告管理"}>
        <HasAuth authField={"uploadAdvertisement"}>
            <Button onClick={()=>navigate("/advertisement/new")}>
                新增
            </Button>
        </HasAuth>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data ? data?.advertisements : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
                pageSize={30}
                pagination={true}
            />
        </Box>
    </Page>
}