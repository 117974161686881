import Page from "../common/Page";
import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import {DataGridLocale, DateValueGetter, HasAuth, ViewColumn} from "../common/Utils";
import {ArticleList, VideoList} from "../common/TestData";
import {useState} from "react";
import {StatusMap} from "../common/Constant";
import {useGetVideosQuery} from "../generated/graphql";
import {useNavigate} from "react-router";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    {field: "title", headerName: "影音名稱", width: 200},
    {field: "author", headerName: "供稿人", width: 100, valueGetter: (params:GridValueGetterParams) =>
            params.value?.email},
    {field: "page", headerName: "所屬頁面", width: 200, valueGetter: (params:GridValueGetterParams) =>
            `${params.value?.title}`},
    {field: "startDate", headerName: "上架日期", width: 100, valueGetter: DateValueGetter},
    {field: "status", headerName: "狀態", width: 100, valueGetter: (params:GridValueGetterParams) =>
            StatusMap[params.value]},
    ViewColumn('/video'),

]
export default () => {
    const navigate = useNavigate();
    const {data} = useGetVideosQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={"影音管理"}>
        <HasAuth authField={"uploadVideo"}>
            <Button onClick={()=>navigate("/video/new")}>
                新增
            </Button>
        </HasAuth>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data ? data?.videos : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
            />
        </Box>
    </Page>
}