import Page from "../common/Page";
import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {Box} from "@mui/material";
import {BooleanValueGetter, DataGridLocale, DateValueGetter, ViewColumn} from "../common/Utils";
import {ArticleList, CustomerList, GuestList, PageList, RoleList, UserList} from "../common/TestData";
import {useState} from "react";
import {useGetUsersQuery} from "../generated/graphql";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    {field: "email", headerName: "帳號名稱", width: 200},
    {field: "enabled", headerName: "狀態", width: 100, valueGetter: (params)=>
            params.value ? "啟用中" : "停用中"},
    ViewColumn('/guest'),
]

export default () => {
    const {data} = useGetUsersQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={"一般會員管理"}>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data ? data.users : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
            />
        </Box>
    </Page>
}