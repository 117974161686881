import Page from "../common/Page";
import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import {BooleanValueGetter, DataGridLocale, DateValueGetter, ViewColumn} from "../common/Utils";
import {ArticleList, RoleList} from "../common/TestData";
import {useState} from "react";
import {StatusMap} from "../common/Constant";
import {useGetRolesQuery} from "../generated/graphql";
import {useNavigate} from "react-router";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    {field: "name", headerName: "角色名稱", width: 120},
    {field: "permission", headerName: "權限管理", width: 100, valueGetter: BooleanValueGetter},
    {field: "page", headerName: "頁面管理", width: 100, valueGetter: BooleanValueGetter},
    {field: "client", headerName: "客戶資料管理", width: 100, valueGetter: BooleanValueGetter},
    {field: "category", headerName: "類別管理", width: 100, valueGetter: BooleanValueGetter},
    {field: "tag", headerName: "關鍵字管理", width: 100, valueGetter: BooleanValueGetter},
    {field: "uploadArticle", headerName: "文章上傳", width: 100, valueGetter: BooleanValueGetter},
    {field: "uploadAdvertisement", headerName: "廣告上傳", width: 100, valueGetter: BooleanValueGetter},
    {field: "uploadVideo", headerName: "影音上傳", width: 100, valueGetter: BooleanValueGetter},
    {field: "publishArticle", headerName: "發布稿件", width: 100, valueGetter: BooleanValueGetter},
    {field: "publishAdvertisement", headerName: "發布廣告", width: 100, valueGetter: BooleanValueGetter},
    {field: "publishVideo", headerName: "發布影音", width: 100, valueGetter: BooleanValueGetter},
    {field: "enabled", headerName: "狀態", width: 100, valueGetter: (params)=>
            params.value ? "啟用中" : "停用中"},

    ViewColumn('/role'),

]
export default () => {
    const navigate = useNavigate();
    const {data} = useGetRolesQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={"角色設定"}>
        <Button onClick={()=>navigate("/role/new")}>
            新增
        </Button>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data ? data?.roles : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
            />
        </Box>
    </Page>
}