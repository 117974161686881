import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {useParams} from "react-router-dom";
import {
    useCreateBackstageUserMutation,
    useGetBackstageUserLazyQuery, useRemoveBackstageUserMutation,
    useUpdateBackstageUserMutation,
} from "../generated/graphql";
import {selectMapFn, useRoles} from "../common/Utils";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetBackstageUserLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const roles = useRoles();
    const [updateBackstageUser] = useUpdateBackstageUserMutation();
    const [createBackstageUser] = useCreateBackstageUserMutation();
    const [removeBackstageUser] = useRemoveBackstageUserMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (backstageUser, { resetForm}) => {
            try {
                if (isNew) {
                    const newBackstageUser = await createBackstageUser(({
                        variables: {
                            backstageUser,
                        }
                    }));
                    NotificationPopup.success(`新增完成`);
                    navigate(`/user/${newBackstageUser.data?.createBackstageUser.id}`);
                    window.location.reload();
                }
                else {
                    await updateBackstageUser({
                        variables: {
                            id: parseInt(id as string),
                            backstageUser: omit(backstageUser, ['id']),
                        },
                        refetchQueries: ["getBackstageUser"]
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`發生問題`);
                console.error(e);
            }
        },[data]
    );
    const columns = [
        {name: "name", label: "帳號名稱", disabled:true, value: data?.backstageUser.name},
        {name: "email", label: "登入EMAIL", value: data?.backstageUser.email},
        {name: "phone", label: "聯絡電話", value: data?.backstageUser.phone},
        {name: "address", label: "聯絡地址", value: data?.backstageUser.address},
        {name: "unit", label: "業務單位", value: data?.backstageUser.unit},
        {name: "title", label: "職稱", value: data?.backstageUser.title},
        {name: "roleId", label: "角色權限", type: InputType.select, options: (roles as any)?.map(selectMapFn), value: data?.backstageUser.role?.id},
        {name: "note", label: "備註", value: data?.backstageUser.note},
        {name: "enabled", label: "是否啟用", type: InputType.switch, value: data?.backstageUser.enabled},
    ]
    const onRemove = async () => {
        await removeBackstageUser({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getBackstageUsers"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate("/user");
    }

    return (
        <Page title={`${isNew ? "新增" : "編輯"}帳號`}>
            {((isNew && roles) || data?.backstageUser) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
            />}
        </Page>
    )
}