import Page from "../common/Page";
import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import {DataGridLocale, DateValueGetter, HasAuth, ViewColumn} from "../common/Utils";
import {ArticleList} from "../common/TestData";
import {useState} from "react";
import {StatusMap} from "../common/Constant";
import {useGetArticlesQuery} from "../generated/graphql";
import {useNavigate} from "react-router";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    {field: "title", headerName: "標題", width: 200},
    {field: "author", headerName: "供稿人", width: 100, valueGetter: (params:GridValueGetterParams) =>
    params.value?.name},
    {field: "subPage", headerName: "所屬頁面", width: 200, valueGetter: (params:GridValueGetterParams) =>
            `${params.value?.page?.title} -> ${params.value?.title}`},
    {field: "startDate", headerName: "上架日期", width: 100, valueGetter: DateValueGetter},

    {field: "content", headerName: "字數", width: 100, valueGetter: (params:GridValueGetterParams) =>
    params.value.length},
    // {field: "views", headerName: "瀏覽量", width: 100},
    {field: "status", headerName: "狀態", width: 100, valueGetter: (params:GridValueGetterParams) =>
    StatusMap[params.value]},
    ViewColumn('/article'),

]
export default () => {
    const navigate = useNavigate();
    const {loading, data, error} = useGetArticlesQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={"文章管理"}>
        <HasAuth authField={"uploadArticle"}>
            <Button onClick={()=>navigate("/article/new")}>
                新增
            </Button>
        </HasAuth>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data ? data?.articles : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
                pageSize={30}
                pagination={true}
            />
        </Box>
    </Page>
}