import Page from "../common/Page";
import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import {BooleanValueGetter, DataGridLocale, DateValueGetter, ViewColumn} from "../common/Utils";
import {ArticleList, CustomerList, PageList, RoleList, UserList} from "../common/TestData";
import {useState} from "react";
import {StatusMap} from "../common/Constant";
import {useGetClientsQuery} from "../generated/graphql";
import {useNavigate} from "react-router";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    {field: "name", headerName: "客戶名稱", width: 120},
    {field: "type", headerName: "公司類別", width: 120},
    {field: "phone", headerName: "公司電話", width: 120},
    {field: "contactName", headerName: "聯絡人姓名", width: 120},
    {field: "contactPhone", headerName: "聯絡電話", width: 120},
    {field: "contactEmail", headerName: "聯絡EMAIL", width: 120},
    ViewColumn('/client'),
]
export default () => {
    const navigate = useNavigate();
    const {data} = useGetClientsQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={"客戶資料管理"}>
        <Button onClick={()=>navigate("/client/new")}>
            新增
        </Button>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data ? data.clients : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
            />
        </Box>
    </Page>
}