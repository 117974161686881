import {Grid} from "@mui/material";
import ReviewItem from "./ReviewItem";
import ManageItem from "./ManageItem";
import {Status, useGetHomeQuery} from "../generated/graphql";
import {reduce} from "lodash";

export default () => {
    const {data} = useGetHomeQuery();

    return <div>
        <Grid container spacing={2} style={{margin: 20}}>
            <Grid item>
                <ReviewItem title={"待審核文章"} count={reduce(data?.articles, (acc, article)=>acc+(article.status==Status.Reviewing ? 1 : 0), 0)} url={"/article"}></ReviewItem>
            </Grid>
            <Grid item>
                <ReviewItem title={"待審核廣告"} count={reduce(data?.advertisements, (acc, advertisement)=>acc+(advertisement.status==Status.Reviewing ? 1 : 0), 0)} url={"/advertisement"}></ReviewItem>
            </Grid>
            <Grid item>
                <ReviewItem title={"待審核影片"} count={reduce(data?.videos, (acc, video)=>acc+(video.status==Status.Reviewing ? 1 : 0), 0)} url={"/video"}></ReviewItem>
            </Grid>
        </Grid>
        <Grid container spacing={2} style={{margin: 20}}>
            <Grid item>
                <ManageItem title={"文章管理"} button1={{title: "文章列表", url: "/article"}} button2={{title: "新增文章上稿", url: "/article/new"}}></ManageItem>
            </Grid>
            <Grid item>
                <ManageItem title={"廣告管理"} button1={{title: "廣告列表", url: "/advertisement"}} button2={{title: "新增廣告", url: "/advertisement/new"}}></ManageItem>
            </Grid>
            <Grid item>
                <ManageItem title={"影片管理"} button1={{title: "影片列表", url: "/video"}} button2={{title: "新增影片", url: "/video/new"}}></ManageItem>
            </Grid>
            <Grid item>
                <ManageItem title={"權限管理"} button1={{title: "角色設定", url: "/role"}} button2={{title: "帳號設定", url: "/role/new"}}></ManageItem>
            </Grid>
            <Grid item>
                <ManageItem title={"頁面管理"} button1={{title: "頁面列表", url: "/page"}} button2={{title: "新增子頁面", url: "/page/new"}}></ManageItem>
            </Grid>
            <Grid item>
                <ManageItem title={"進階搜尋設定"} button1={{title: "分類項目列表", url: "/search"}} button2={{title: "新增搜尋分類", url: "/search/new"}}></ManageItem>
            </Grid>
            <Grid item>
                <ManageItem title={"商業客戶資料管理"} button1={{title: "客戶資料列表", url: "/client"}} button2={{title: "新增客戶資料", url: "/client/new"}}></ManageItem>
            </Grid>
            <Grid item>
                <ManageItem title={"類別管理"} button1={{title: "類別列表", url: "/category"}} button2={{title: "新增類別", url: "/category/new"}}></ManageItem>
            </Grid>
            <Grid item>
                <ManageItem title={"頁面管理"} button1={{title: "一般會員管理", url: "/guess"}}></ManageItem>
            </Grid>
        </Grid>
    </div>
}