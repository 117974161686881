import {Box, Avatar, Divider, Drawer, Hidden, Typography, Select, MenuItem, Button} from "@mui/material";
import {makeStyles} from '@mui/styles';
import React, {useContext, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
// import {useCompany, useUser} from "../auth/Auth";
import {
    BookOpen,
    Briefcase, Home, Paperclip,
} from "react-feather";
import MyMenuItem from "./MyMenuItem";
// import {Authorization, RoleEnum} from "../generated/graphql";
// import {hasAuthorization, hasRole} from "../common/Utils";
// import Logo from "../image/logo.png";
// import UserIcon from "../image/user.svg";
// import CompanyIcon from "../image/company.svg";
// import LaborContractIcon from "../image/labor_contract.svg";
// import ReportIcon from "../image/report.svg";
// import ContractorIcon from "../image/contractor.svg";
// import {find} from "lodash";
// import {CompanyContext} from "../routes";
import {format} from "date-fns";
import {HasAuth, useMe} from "../common/Utils";
import {setAccessToken} from "../auth/Auth";
// import {AuthorizationMap} from "../common/Constant";

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 200,
    },
    desktopDrawer: {
        width: 200,
        height: "calc(100%)",
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
}));
const items = [
    {
        href: "/article",
        title: "文章管理",
    },
    {
        href: "/advertisement",
        title: "廣告管理",
    },
    {
        href: "/video",
        title: "影音管理",
    },
    {
        href: "/role",
        title: "角色設定",
        authField: "permission",
    },
    {
        href: "/user",
        title: "帳號設定",
        authField: "permission",
    },
    {
        href: "/page",
        title: "頁面管理",
        authField: "page",
    },
    {
        href: "/search",
        title: "進階搜尋設定",
        authField: "tag",
    },
    {
        href: "/client",
        title: "客戶資料管理",
        authField: "client",
    },
    {
        href: "/category",
        title: "類別管理",
        authField: "category",
    },
    {
        href: "/guest",
        title: "一般會員管理",
    },
]

const MenuBar = ({onMobileClose, openMobile}: any) => {
    const classes = useStyles();
    const location = useLocation();
    const me = useMe();
    // const user = useUser();
    // useEffect(()=>{
    //     if (company==null) {
    //         setCompany(user?.companies![0]);
    //     }
    // },[user]);

    // const company = useContext(CompanyContext);

    // let companyAuthorization:any = null;
    // if (location.pathname.includes('laborContract') || location.pathname.includes('contractor')) {
    //     companyAuthorization = find(company?.companyAuthorizations, ({authorization})=>authorization==Authorization.LaborContract);
    // }
    // else if (location.pathname.includes('report')) {
    //     companyAuthorization = find(company?.companyAuthorizations, ({authorization})=>authorization==Authorization.Finance);
    // }
    // else if (location.pathname.includes('employee') || location.pathname.includes('leave') || location.pathname.includes('salary')) {
    //     companyAuthorization = find(company?.companyAuthorizations, ({authorization})=>authorization==Authorization.Personnel);
    // }
    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <Avatar
                    className={classes.avatar}
                    component={Link}
                    src={me?.picture as string}
                    to="/"
                />
                <Typography
                    color="textPrimary"
                    variant="h5"
                    style={{marginBottom: 10}}
                >
                    {me?.name}
                </Typography>
                <Button onClick={()=>{
                    setAccessToken('');
                    window.location.reload();
                }}>
                    登出
                </Button>
            </Box>
            <Divider style={{borderColor: "#DDD"}}/>
            <Box
                p={2}
                alignItems="center"
                display="flex"
                flexDirection="column"
            >
                {items.map((item) => {
                    let menuItem = <MyMenuItem
                        style={{width: 150}}
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        // icon={item.icon}
                        selected={location.pathname.includes(item.href)}
                    />
                    return <HasAuth authField={item.authField}>{menuItem}</HasAuth>;
                })}
            </Box>
            <div style={{position: "absolute", display: "grid", justifyContent: "center",
                bottom: "0px", width: "100%", marginBottom: 50}}>
                {/*<img src={Logo} style={{width: 66, height: 66}}/>*/}
                {/*<small>*/}
                {/*    Build Time:&nbsp;*/}
                {/*    {preval`module.exports = new Date().toLocaleString();`}*/}
                {/*</small>*/}
            </div>
        </Box>
    );
    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                    PaperProps={{style: {borderColor: "#DDD"}}}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

export default MenuBar;