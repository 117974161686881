import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {CategoryList, CustomerList, OrderList, PageSelectList} from "../common/TestData";
import {selectMapFn, useCategories, useClients, useMe, usePages} from "../common/Utils";
import {StatusList} from "../common/Constant";
import {useParams} from "react-router-dom";
import {
    Status,
    useCreateAdvertisementMutation,
    useGetAdvertisementLazyQuery, useRemoveAdvertisementMutation,
    useUpdateAdvertisementMutation,
    useUpdateArticleMutation
} from "../generated/graphql";
import {useCallback, useEffect} from "react";
import {pageColumn} from "../article/ArticleDetail";
import {format} from "date-fns";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {useNavigate} from "react-router";
import {Button} from "@mui/material";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetAdvertisementLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const me = useMe();
    const pages = usePages();
    const clients = useClients();
    const [updateAdvertisement] = useUpdateAdvertisementMutation();
    const [createAdvertisement] = useCreateAdvertisementMutation();
    const [removeAdvertisement] = useRemoveAdvertisementMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (advertisement, { resetForm}) => {
            try {
                if (advertisement.discountPercent) {
                    advertisement.discountPercent = parseFloat(advertisement.discountPercent)/100;
                }
                if (advertisement.commissionPercent) {
                    advertisement.commissionPercent = parseFloat(advertisement.commissionPercent)/100;
                }
                if (isNew) {
                    const newAdvertisement = await createAdvertisement({
                        variables: {
                            advertisement
                        }
                    });
                    NotificationPopup.success(`新增完成`);
                    navigate(`/advertisement/${newAdvertisement.data?.createAdvertisement.id}`);
                    window.location.reload();
                }
                else {
                    await updateAdvertisement({
                        variables: {
                            id: parseInt(id as string),
                            advertisement: omit(advertisement, ['id']),
                        },
                        refetchQueries: ["getAdvertisement"],
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`發生問題`);
                console.error(e);
            }
        },[data]
    );
    const onReview = async ()=>{
        await updateAdvertisement({
            variables: {
                id: parseInt(id as string),
                advertisement: {
                    status: Status.Reviewing,
                }
            },
            refetchQueries: ["getAdvertisement"],
        });
        NotificationPopup.success(`送審成功`);
        window.location.reload();
    }
    const onRemove = async () => {
        await removeAdvertisement({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getAdvertisements"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate("/advertisement");
    }
    const columns = [
        {name: "id", label: "", type: InputType.hidden, value: data?.advertisement?.id},
        {name: "status", label: isNew ? "" : "狀態", type: isNew ? InputType.hidden : InputType.select, options: StatusList, value: data?.advertisement?.status, disabled: !(me?.role?.publishAdvertisement)},
        pageColumn(pages, data?.advertisement?.subPage?.id, data?.advertisement?.page?.id),
        {name: "index", label: "廣告刊登位置", type: InputType.select, options: OrderList, value: data?.advertisement?.index},
        {name: "clientId", label: "客戶名稱", type: InputType.autoComplete, options: (clients as any)?.map(selectMapFn), value: data?.advertisement?.client?.id},
        {name: "productName", label: "產品名稱", value: data?.advertisement.productName},
        {name: "eventName", label: "活動名稱", value: data?.advertisement.eventName},
        {name: "startDate", label: "刊登日期", type: InputType.date, value: isNew ? null : format(new Date(data?.advertisement?.startDate ?? null), "yyyy-MM-dd")},
        {name: "endDate", label: "結束日期", type: InputType.date, value: isNew ? null : format(new Date(data?.advertisement?.endDate ?? null), "yyyy-MM-dd")},
        {name: "price", label: "表定價格", type: InputType.number, value: data?.advertisement.price},
        {name: "imageId", label: "廣告圖檔上傳", type: InputType.file, value: data?.advertisement.imageId},
        {name: "imageUrl", label: "圖檔連結", value: data?.advertisement.imageUrl},
        {name: "note", label: "備註", value: data?.advertisement.note},
        {name: "isAcceptedPublish", label: "是否發布", type: InputType.switch, value: data?.advertisement.isAcceptedPublish, disabled: !(me?.role?.publishAdvertisement)},
        {name: "discountPercent", label: "優惠%數", value: (data?.advertisement.discountPercent ?? 0)*100},
        // {name: "realPrice", label: "實收價格", disabled: true},
        {name: "commissionPercent", label: "佣金%數", value: (data?.advertisement?.commissionPercent ?? 0)*100},
        // {name: "commission", label: "佣金", disabled: true},
    ]

    return (
        <Page title={`${isNew ? "新增" : "編輯"}廣告`}>
            {((isNew && me && pages && clients) || data?.advertisement) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
                onReview={data?.advertisement?.status==Status.Editing ? onReview : null}
            />}
        </Page>
    )
}