import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Advertisement = {
  __typename?: 'Advertisement';
  client?: Maybe<Client>;
  commissionPercent?: Maybe<Scalars['Float']>;
  createAt: Scalars['DateTime'];
  discountPercent?: Maybe<Scalars['Float']>;
  endDate: Scalars['DateTime'];
  eventName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageId?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isAcceptedPublish: Scalars['Boolean'];
  isHomePageBanner: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
  price?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: Status;
  subPage?: Maybe<SubPage>;
  updateAt: Scalars['DateTime'];
};

export enum AdvertisementGridLayout {
  Four = 'four',
  One = 'one',
  Three = 'three',
  Two = 'two'
}

export type AdvertisementInput = {
  clientId?: Maybe<Scalars['Int']>;
  commissionPercent?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventName?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isAcceptedPublish?: Maybe<Scalars['Boolean']>;
  isHomePageBanner?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Status>;
  subPageId?: Maybe<Scalars['Int']>;
};

export type Article = {
  __typename?: 'Article';
  author?: Maybe<BackstageUser>;
  bannerId?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  content: Scalars['String'];
  createAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  imageId?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isAcceptedPublish: Scalars['Boolean'];
  isEditorChoice: Scalars['Boolean'];
  managerNote?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  startDate: Scalars['DateTime'];
  status: Status;
  subPage?: Maybe<SubPage>;
  tags: Scalars['String'];
  title: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export enum ArticleGridLayout {
  Eight = 'eight',
  Five = 'five',
  One = 'one',
  Two = 'two'
}

export type ArticleInput = {
  authorId?: Maybe<Scalars['Int']>;
  bannerId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  imageId?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isAcceptedPublish?: Maybe<Scalars['Boolean']>;
  isEditorChoice?: Maybe<Scalars['Boolean']>;
  managerNote?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Status>;
  subPageId?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BackstageUser = {
  __typename?: 'BackstageUser';
  address?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  googleId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  title?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type BackstageUserInput = {
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  googleId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  createAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type CategoryInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  address?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactItem?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactUnit?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ClientInput = {
  address?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactItem?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactUnit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FilterCategory = {
  __typename?: 'FilterCategory';
  enabled: Scalars['Boolean'];
  filterItems?: Maybe<Array<FilterItem>>;
  id: Scalars['Int'];
  index: Scalars['Int'];
  name: Scalars['String'];
};

export type FilterCategoryInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type FilterItem = {
  __typename?: 'FilterItem';
  enabled: Scalars['Boolean'];
  filterCategory?: Maybe<FilterItem>;
  id: Scalars['Int'];
  index: Scalars['Int'];
  name: Scalars['String'];
};

export type FilterItemInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  filterCategoryId?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAdvertisement: Advertisement;
  createArticle: Article;
  createBackstageUser: BackstageUser;
  createCategory: Category;
  createClient: Client;
  createFilterCategory: FilterCategory;
  createFilterItem: FilterItem;
  createPage: Page;
  createRole: Role;
  createSubPage: SubPage;
  createUser: User;
  createVideo: Video;
  removeAdvertisement: Scalars['Int'];
  removeArticle: Scalars['Int'];
  removeBackstageUser: Scalars['Int'];
  removeCategory: Scalars['Int'];
  removeClient: Scalars['Int'];
  removeFilterCategory: Scalars['Int'];
  removeFilterItem: Scalars['Int'];
  removePage: Scalars['Int'];
  removeRole: Scalars['Int'];
  removeSubPage: Scalars['Int'];
  removeUser: Scalars['Int'];
  removeVideo: Scalars['Int'];
  updateAdvertisement: Advertisement;
  updateArticle: Article;
  updateBackstageUser: BackstageUser;
  updateCategory: Category;
  updateClient: Client;
  updateFilterCategory: FilterCategory;
  updateFilterItem: FilterItem;
  updatePage: Page;
  updateRole: Role;
  updateSubPage: SubPage;
  updateUser: User;
  updateVideo: Video;
};


export type MutationCreateAdvertisementArgs = {
  advertisement: AdvertisementInput;
};


export type MutationCreateArticleArgs = {
  article: ArticleInput;
};


export type MutationCreateBackstageUserArgs = {
  backstageUser: BackstageUserInput;
};


export type MutationCreateCategoryArgs = {
  category: CategoryInput;
};


export type MutationCreateClientArgs = {
  client: ClientInput;
};


export type MutationCreateFilterCategoryArgs = {
  filterCategory: FilterCategoryInput;
};


export type MutationCreateFilterItemArgs = {
  filterItem: FilterItemInput;
};


export type MutationCreatePageArgs = {
  page: PageInput;
};


export type MutationCreateRoleArgs = {
  role: RoleInput;
};


export type MutationCreateSubPageArgs = {
  subPage: SubPageInput;
};


export type MutationCreateUserArgs = {
  user: UserInput;
};


export type MutationCreateVideoArgs = {
  video: VideoInput;
};


export type MutationRemoveAdvertisementArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveArticleArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveBackstageUserArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveClientArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveFilterCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveFilterItemArgs = {
  id: Scalars['Int'];
};


export type MutationRemovePageArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveRoleArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveSubPageArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveVideoArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateAdvertisementArgs = {
  advertisement: AdvertisementInput;
  id: Scalars['Int'];
};


export type MutationUpdateArticleArgs = {
  article: ArticleInput;
  id: Scalars['Int'];
};


export type MutationUpdateBackstageUserArgs = {
  backstageUser: BackstageUserInput;
  id: Scalars['Int'];
};


export type MutationUpdateCategoryArgs = {
  category: CategoryInput;
  id: Scalars['Int'];
};


export type MutationUpdateClientArgs = {
  client: ClientInput;
  id: Scalars['Int'];
};


export type MutationUpdateFilterCategoryArgs = {
  filterCategory: FilterCategoryInput;
  id: Scalars['Int'];
};


export type MutationUpdateFilterItemArgs = {
  filterItem: FilterItemInput;
  id: Scalars['Int'];
};


export type MutationUpdatePageArgs = {
  id: Scalars['Int'];
  page: PageInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['Int'];
  role: RoleInput;
};


export type MutationUpdateSubPageArgs = {
  id: Scalars['Int'];
  subPage: SubPageInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  user: UserInput;
};


export type MutationUpdateVideoArgs = {
  id: Scalars['Int'];
  video: VideoInput;
};

export type Page = {
  __typename?: 'Page';
  advertisementGridLayout: AdvertisementGridLayout;
  advertisements: Array<Advertisement>;
  articleGridLayout: ArticleGridLayout;
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  index?: Maybe<Scalars['Int']>;
  subPages?: Maybe<Array<SubPage>>;
  title: Scalars['String'];
};

export type PageInput = {
  advertisementGridLayout?: Maybe<AdvertisementGridLayout>;
  articleGridLayout?: Maybe<ArticleGridLayout>;
  enabled?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  advertisement: Advertisement;
  advertisements: Array<Advertisement>;
  article?: Maybe<Article>;
  articles: Array<Article>;
  backstageUser: BackstageUser;
  backstageUsers: Array<BackstageUser>;
  categories: Array<Category>;
  category: Category;
  client: Client;
  clients: Array<Client>;
  filterCategories: Array<FilterCategory>;
  filterCategory: FilterCategory;
  filterItem: FilterItem;
  filterItems: Array<FilterItem>;
  getHomePage: Array<Page>;
  getHomePageAdvertisement?: Maybe<Advertisement>;
  me: BackstageUser;
  page: Page;
  pages: Array<Page>;
  role: Role;
  roles: Array<Role>;
  subPage: SubPage;
  subPages: Array<SubPage>;
  user: User;
  users: Array<User>;
  video: Video;
  videos: Array<Video>;
};


export type QueryAdvertisementArgs = {
  id: Scalars['Int'];
};


export type QueryArticleArgs = {
  id: Scalars['Int'];
};


export type QueryBackstageUserArgs = {
  id: Scalars['Int'];
};


export type QueryCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryClientArgs = {
  id: Scalars['Int'];
};


export type QueryFilterCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryFilterItemArgs = {
  id: Scalars['Int'];
};


export type QueryPageArgs = {
  id: Scalars['Int'];
};


export type QueryRoleArgs = {
  id: Scalars['Int'];
};


export type QuerySubPageArgs = {
  id: Scalars['Int'];
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryVideoArgs = {
  id: Scalars['Int'];
};

export type Role = {
  __typename?: 'Role';
  category: Scalars['Boolean'];
  client: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  page: Scalars['Boolean'];
  permission: Scalars['Boolean'];
  publishAdvertisement: Scalars['Boolean'];
  publishArticle: Scalars['Boolean'];
  publishVideo: Scalars['Boolean'];
  tag: Scalars['Boolean'];
  uploadAdvertisement: Scalars['Boolean'];
  uploadArticle: Scalars['Boolean'];
  uploadVideo: Scalars['Boolean'];
};

export type RoleInput = {
  category?: Maybe<Scalars['Boolean']>;
  client?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Boolean']>;
  permission?: Maybe<Scalars['Boolean']>;
  publishAdvertisement?: Maybe<Scalars['Boolean']>;
  publishArticle?: Maybe<Scalars['Boolean']>;
  publishVideo?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['Boolean']>;
  uploadAdvertisement?: Maybe<Scalars['Boolean']>;
  uploadArticle?: Maybe<Scalars['Boolean']>;
  uploadVideo?: Maybe<Scalars['Boolean']>;
};

export enum Status {
  Editing = 'editing',
  Publishing = 'publishing',
  Rejected = 'rejected',
  Reviewing = 'reviewing',
  Scheduling = 'scheduling',
  Unpublish = 'unpublish'
}

export type SubPage = {
  __typename?: 'SubPage';
  advertisementGridLayout: AdvertisementGridLayout;
  advertisements: Array<Advertisement>;
  articleGridLayout: ArticleGridLayout;
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  index?: Maybe<Scalars['Int']>;
  page: Page;
  title: Scalars['String'];
};

export type SubPageInput = {
  advertisementGridLayout?: Maybe<AdvertisementGridLayout>;
  articleGridLayout?: Maybe<ArticleGridLayout>;
  enabled?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
};

export type UserInput = {
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  author?: Maybe<BackstageUser>;
  category?: Maybe<Category>;
  createAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  fileId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  index?: Maybe<Scalars['Int']>;
  isAcceptedPublish: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
  startDate: Scalars['DateTime'];
  status: Status;
  title: Scalars['String'];
  updateAt: Scalars['DateTime'];
  videoUrl?: Maybe<Scalars['String']>;
};

export type VideoInput = {
  authorId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  fileId?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isAcceptedPublish?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type CreateAdvertisementMutationVariables = Exact<{
  advertisement: AdvertisementInput;
}>;


export type CreateAdvertisementMutation = { __typename?: 'Mutation', createAdvertisement: { __typename?: 'Advertisement', id: number } };

export type GetAdvertisementQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAdvertisementQuery = { __typename?: 'Query', advertisement: { __typename?: 'Advertisement', id: number, status: Status, index?: number | null | undefined, productName?: string | null | undefined, eventName?: string | null | undefined, startDate: any, endDate: any, price?: number | null | undefined, imageId?: string | null | undefined, imageUrl?: string | null | undefined, note?: string | null | undefined, discountPercent?: number | null | undefined, commissionPercent?: number | null | undefined, isAcceptedPublish: boolean, isHomePageBanner: boolean, page?: { __typename?: 'Page', id: number, title: string } | null | undefined, subPage?: { __typename?: 'SubPage', id: number, title: string, page: { __typename?: 'Page', id: number, title: string } } | null | undefined, client?: { __typename?: 'Client', id: number, name: string } | null | undefined } };

export type GetAdvertisementsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdvertisementsQuery = { __typename?: 'Query', advertisements: Array<{ __typename?: 'Advertisement', id: number, productName?: string | null | undefined, eventName?: string | null | undefined, startDate: any, endDate: any, status: Status, price?: number | null | undefined, discountPercent?: number | null | undefined, commissionPercent?: number | null | undefined, page?: { __typename?: 'Page', id: number, title: string } | null | undefined, subPage?: { __typename?: 'SubPage', id: number, title: string, page: { __typename?: 'Page', id: number, title: string } } | null | undefined }> };

export type RemoveAdvertisementMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveAdvertisementMutation = { __typename?: 'Mutation', removeAdvertisement: number };

export type UpdateAdvertisementMutationVariables = Exact<{
  id: Scalars['Int'];
  advertisement: AdvertisementInput;
}>;


export type UpdateAdvertisementMutation = { __typename?: 'Mutation', updateAdvertisement: { __typename?: 'Advertisement', id: number } };

export type CreateArticleMutationVariables = Exact<{
  article: ArticleInput;
}>;


export type CreateArticleMutation = { __typename?: 'Mutation', createArticle: { __typename?: 'Article', id: number } };

export type GetArticleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Article', id: number, status: Status, title: string, price: number, startDate: any, endDate: any, tags: string, index: number, content: string, managerNote?: string | null | undefined, isEditorChoice: boolean, isAcceptedPublish: boolean, imageId?: string | null | undefined, bannerId?: string | null | undefined, author?: { __typename?: 'BackstageUser', id: number, name?: string | null | undefined } | null | undefined, category?: { __typename?: 'Category', id: number, name: string } | null | undefined, subPage?: { __typename?: 'SubPage', id: number, title: string, page: { __typename?: 'Page', id: number, title: string } } | null | undefined } | null | undefined };

export type GetArticlesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetArticlesQuery = { __typename?: 'Query', articles: Array<{ __typename?: 'Article', id: number, status: Status, title: string, price: number, startDate: any, endDate: any, tags: string, index: number, content: string, managerNote?: string | null | undefined, isEditorChoice: boolean, isAcceptedPublish: boolean, author?: { __typename?: 'BackstageUser', id: number, name?: string | null | undefined } | null | undefined, category?: { __typename?: 'Category', id: number, name: string } | null | undefined, subPage?: { __typename?: 'SubPage', id: number, title: string, page: { __typename?: 'Page', id: number, title: string } } | null | undefined }> };

export type RemoveArticleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveArticleMutation = { __typename?: 'Mutation', removeArticle: number };

export type UpdateArticleMutationVariables = Exact<{
  id: Scalars['Int'];
  article: ArticleInput;
}>;


export type UpdateArticleMutation = { __typename?: 'Mutation', updateArticle: { __typename?: 'Article', id: number } };

export type CreateBackstageUserMutationVariables = Exact<{
  backstageUser: BackstageUserInput;
}>;


export type CreateBackstageUserMutation = { __typename?: 'Mutation', createBackstageUser: { __typename?: 'BackstageUser', id: number } };

export type GetBackstageUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetBackstageUserQuery = { __typename?: 'Query', backstageUser: { __typename?: 'BackstageUser', id: number, name?: string | null | undefined, email: string, phone?: string | null | undefined, address?: string | null | undefined, unit?: string | null | undefined, title?: string | null | undefined, note?: string | null | undefined, enabled: boolean, role?: { __typename?: 'Role', id: number, name: string } | null | undefined } };

export type GetBackstageUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBackstageUsersQuery = { __typename?: 'Query', backstageUsers: Array<{ __typename?: 'BackstageUser', id: number, name?: string | null | undefined, phone?: string | null | undefined, enabled: boolean, role?: { __typename?: 'Role', id: number, name: string } | null | undefined }> };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me: { __typename?: 'BackstageUser', id: number, googleId?: string | null | undefined, picture?: string | null | undefined, email: string, name?: string | null | undefined, role?: { __typename?: 'Role', permission: boolean, page: boolean, client: boolean, category: boolean, tag: boolean, uploadArticle: boolean, uploadAdvertisement: boolean, uploadVideo: boolean, publishArticle: boolean, publishAdvertisement: boolean, publishVideo: boolean } | null | undefined } };

export type RemoveBackstageUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveBackstageUserMutation = { __typename?: 'Mutation', removeBackstageUser: number };

export type UpdateBackstageUserMutationVariables = Exact<{
  id: Scalars['Int'];
  backstageUser: BackstageUserInput;
}>;


export type UpdateBackstageUserMutation = { __typename?: 'Mutation', updateBackstageUser: { __typename?: 'BackstageUser', id: number } };

export type CreateCategoryMutationVariables = Exact<{
  category: CategoryInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createCategory: { __typename?: 'Category', id: number } };

export type GetCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCategoryQuery = { __typename?: 'Query', category: { __typename?: 'Category', id: number, name: string, index?: number | null | undefined, enabled: boolean } };

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: number, name: string, index?: number | null | undefined, enabled: boolean }> };

export type RemoveCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveCategoryMutation = { __typename?: 'Mutation', removeCategory: number };

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  category: CategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateCategory: { __typename?: 'Category', id: number } };

export type CreateClientMutationVariables = Exact<{
  client: ClientInput;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createClient: { __typename?: 'Client', id: number } };

export type GetClientQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetClientQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: number, name: string, type?: string | null | undefined, address?: string | null | undefined, taxId?: string | null | undefined, phone?: string | null | undefined, contactName?: string | null | undefined, contactTitle?: string | null | undefined, contactUnit?: string | null | undefined, contactItem?: string | null | undefined, contactPhone?: string | null | undefined, contactEmail?: string | null | undefined, note?: string | null | undefined } };

export type GetClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'Client', id: number, name: string, type?: string | null | undefined, phone?: string | null | undefined, contactName?: string | null | undefined, contactPhone?: string | null | undefined, contactEmail?: string | null | undefined }> };

export type RemoveClientMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveClientMutation = { __typename?: 'Mutation', removeClient: number };

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['Int'];
  client: ClientInput;
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient: { __typename?: 'Client', id: number } };

export type CreateFilterCategoryMutationVariables = Exact<{
  filterCategory: FilterCategoryInput;
}>;


export type CreateFilterCategoryMutation = { __typename?: 'Mutation', createFilterCategory: { __typename?: 'FilterCategory', id: number } };

export type GetFilterCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFilterCategoryQuery = { __typename?: 'Query', filterCategory: { __typename?: 'FilterCategory', id: number, name: string, index: number, enabled: boolean, filterItems?: Array<{ __typename?: 'FilterItem', id: number, name: string, index: number, enabled: boolean }> | null | undefined } };

export type GetFilterCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFilterCategoriesQuery = { __typename?: 'Query', filterCategories: Array<{ __typename?: 'FilterCategory', id: number, name: string, index: number, enabled: boolean }> };

export type RemoveFilterCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveFilterCategoryMutation = { __typename?: 'Mutation', removeFilterCategory: number };

export type UpdateFilterCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  filterCategory: FilterCategoryInput;
}>;


export type UpdateFilterCategoryMutation = { __typename?: 'Mutation', updateFilterCategory: { __typename?: 'FilterCategory', id: number } };

export type CreateFilterItemMutationVariables = Exact<{
  filterItem: FilterItemInput;
}>;


export type CreateFilterItemMutation = { __typename?: 'Mutation', createFilterItem: { __typename?: 'FilterItem', id: number } };

export type GetFilterItemQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFilterItemQuery = { __typename?: 'Query', filterItem: { __typename?: 'FilterItem', id: number, name: string, index: number, enabled: boolean, filterCategory?: { __typename?: 'FilterItem', id: number } | null | undefined } };

export type RemoveFilterItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveFilterItemMutation = { __typename?: 'Mutation', removeFilterItem: number };

export type UpdateFilterItemMutationVariables = Exact<{
  id: Scalars['Int'];
  filterItem: FilterItemInput;
}>;


export type UpdateFilterItemMutation = { __typename?: 'Mutation', updateFilterItem: { __typename?: 'FilterItem', id: number } };

export type GetHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeQuery = { __typename?: 'Query', articles: Array<{ __typename?: 'Article', id: number, status: Status }>, advertisements: Array<{ __typename?: 'Advertisement', id: number, status: Status }>, videos: Array<{ __typename?: 'Video', id: number, status: Status }> };

export type CreatePageMutationVariables = Exact<{
  page: PageInput;
}>;


export type CreatePageMutation = { __typename?: 'Mutation', createPage: { __typename?: 'Page', id: number } };

export type GetPageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPageQuery = { __typename?: 'Query', page: { __typename?: 'Page', id: number, title: string, articleGridLayout: ArticleGridLayout, advertisementGridLayout: AdvertisementGridLayout, enabled: boolean, subPages?: Array<{ __typename?: 'SubPage', id: number, title: string, articleGridLayout: ArticleGridLayout, advertisementGridLayout: AdvertisementGridLayout, enabled: boolean }> | null | undefined } };

export type GetPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPagesQuery = { __typename?: 'Query', pages: Array<{ __typename?: 'Page', id: number, title: string, articleGridLayout: ArticleGridLayout, advertisementGridLayout: AdvertisementGridLayout, enabled: boolean, subPages?: Array<{ __typename?: 'SubPage', id: number, title: string }> | null | undefined }> };

export type RemovePageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemovePageMutation = { __typename?: 'Mutation', removePage: number };

export type UpdatePageMutationVariables = Exact<{
  id: Scalars['Int'];
  page: PageInput;
}>;


export type UpdatePageMutation = { __typename?: 'Mutation', updatePage: { __typename?: 'Page', id: number } };

export type CreateRoleMutationVariables = Exact<{
  role: RoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: number } };

export type GetRoleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetRoleQuery = { __typename?: 'Query', role: { __typename?: 'Role', id: number, name: string, permission: boolean, page: boolean, client: boolean, category: boolean, tag: boolean, uploadArticle: boolean, uploadAdvertisement: boolean, uploadVideo: boolean, publishArticle: boolean, publishAdvertisement: boolean, publishVideo: boolean, enabled: boolean } };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: number, name: string, permission: boolean, page: boolean, client: boolean, category: boolean, tag: boolean, uploadArticle: boolean, uploadAdvertisement: boolean, uploadVideo: boolean, publishArticle: boolean, publishAdvertisement: boolean, publishVideo: boolean, enabled: boolean }> };

export type RemoveRoleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveRoleMutation = { __typename?: 'Mutation', removeRole: number };

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['Int'];
  role: RoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id: number } };

export type CreateSubPageMutationVariables = Exact<{
  subPage: SubPageInput;
}>;


export type CreateSubPageMutation = { __typename?: 'Mutation', createSubPage: { __typename?: 'SubPage', id: number } };

export type GetSubPageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSubPageQuery = { __typename?: 'Query', subPage: { __typename?: 'SubPage', id: number, title: string, articleGridLayout: ArticleGridLayout, advertisementGridLayout: AdvertisementGridLayout, enabled: boolean, page: { __typename?: 'Page', id: number } } };

export type RemoveSubPageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveSubPageMutation = { __typename?: 'Mutation', removeSubPage: number };

export type UpdateSubPageMutationVariables = Exact<{
  id: Scalars['Int'];
  subPage: SubPageInput;
}>;


export type UpdateSubPageMutation = { __typename?: 'Mutation', updateSubPage: { __typename?: 'SubPage', id: number } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: number, email?: string | null | undefined, enabled?: boolean | null | undefined } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, email?: string | null | undefined, enabled?: boolean | null | undefined }> };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeUser: number };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  user: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number } };

export type CreateVideoMutationVariables = Exact<{
  video: VideoInput;
}>;


export type CreateVideoMutation = { __typename?: 'Mutation', createVideo: { __typename?: 'Video', id: number } };

export type GetVideoQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetVideoQuery = { __typename?: 'Query', video: { __typename?: 'Video', id: number, status: Status, index?: number | null | undefined, title: string, startDate: any, endDate: any, fileId?: string | null | undefined, note?: string | null | undefined, isAcceptedPublish: boolean, page?: { __typename?: 'Page', id: number, title: string } | null | undefined, category?: { __typename?: 'Category', id: number, name: string } | null | undefined, author?: { __typename?: 'BackstageUser', id: number, name?: string | null | undefined } | null | undefined } };

export type GetVideosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideosQuery = { __typename?: 'Query', videos: Array<{ __typename?: 'Video', id: number, title: string, startDate: any, status: Status, author?: { __typename?: 'BackstageUser', id: number, email: string } | null | undefined, page?: { __typename?: 'Page', id: number, title: string } | null | undefined }> };

export type RemoveVideoMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveVideoMutation = { __typename?: 'Mutation', removeVideo: number };

export type UpdateVideoMutationVariables = Exact<{
  id: Scalars['Int'];
  video: VideoInput;
}>;


export type UpdateVideoMutation = { __typename?: 'Mutation', updateVideo: { __typename?: 'Video', id: number } };


export const CreateAdvertisementDocument = gql`
    mutation createAdvertisement($advertisement: AdvertisementInput!) {
  createAdvertisement(advertisement: $advertisement) {
    id
  }
}
    `;
export type CreateAdvertisementMutationFn = Apollo.MutationFunction<CreateAdvertisementMutation, CreateAdvertisementMutationVariables>;

/**
 * __useCreateAdvertisementMutation__
 *
 * To run a mutation, you first call `useCreateAdvertisementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdvertisementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdvertisementMutation, { data, loading, error }] = useCreateAdvertisementMutation({
 *   variables: {
 *      advertisement: // value for 'advertisement'
 *   },
 * });
 */
export function useCreateAdvertisementMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdvertisementMutation, CreateAdvertisementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdvertisementMutation, CreateAdvertisementMutationVariables>(CreateAdvertisementDocument, options);
      }
export type CreateAdvertisementMutationHookResult = ReturnType<typeof useCreateAdvertisementMutation>;
export type CreateAdvertisementMutationResult = Apollo.MutationResult<CreateAdvertisementMutation>;
export type CreateAdvertisementMutationOptions = Apollo.BaseMutationOptions<CreateAdvertisementMutation, CreateAdvertisementMutationVariables>;
export const GetAdvertisementDocument = gql`
    query getAdvertisement($id: Int!) {
  advertisement(id: $id) {
    id
    status
    page {
      id
      title
    }
    subPage {
      id
      title
      page {
        id
        title
      }
    }
    index
    client {
      id
      name
    }
    productName
    eventName
    startDate
    endDate
    price
    imageId
    imageUrl
    note
    discountPercent
    commissionPercent
    isAcceptedPublish
    isHomePageBanner
  }
}
    `;

/**
 * __useGetAdvertisementQuery__
 *
 * To run a query within a React component, call `useGetAdvertisementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvertisementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvertisementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdvertisementQuery(baseOptions: Apollo.QueryHookOptions<GetAdvertisementQuery, GetAdvertisementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvertisementQuery, GetAdvertisementQueryVariables>(GetAdvertisementDocument, options);
      }
export function useGetAdvertisementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvertisementQuery, GetAdvertisementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvertisementQuery, GetAdvertisementQueryVariables>(GetAdvertisementDocument, options);
        }
export type GetAdvertisementQueryHookResult = ReturnType<typeof useGetAdvertisementQuery>;
export type GetAdvertisementLazyQueryHookResult = ReturnType<typeof useGetAdvertisementLazyQuery>;
export type GetAdvertisementQueryResult = Apollo.QueryResult<GetAdvertisementQuery, GetAdvertisementQueryVariables>;
export const GetAdvertisementsDocument = gql`
    query getAdvertisements {
  advertisements {
    id
    productName
    eventName
    page {
      id
      title
    }
    subPage {
      id
      title
      page {
        id
        title
      }
    }
    startDate
    endDate
    status
    price
    discountPercent
    commissionPercent
  }
}
    `;

/**
 * __useGetAdvertisementsQuery__
 *
 * To run a query within a React component, call `useGetAdvertisementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvertisementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvertisementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdvertisementsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdvertisementsQuery, GetAdvertisementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvertisementsQuery, GetAdvertisementsQueryVariables>(GetAdvertisementsDocument, options);
      }
export function useGetAdvertisementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvertisementsQuery, GetAdvertisementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvertisementsQuery, GetAdvertisementsQueryVariables>(GetAdvertisementsDocument, options);
        }
export type GetAdvertisementsQueryHookResult = ReturnType<typeof useGetAdvertisementsQuery>;
export type GetAdvertisementsLazyQueryHookResult = ReturnType<typeof useGetAdvertisementsLazyQuery>;
export type GetAdvertisementsQueryResult = Apollo.QueryResult<GetAdvertisementsQuery, GetAdvertisementsQueryVariables>;
export const RemoveAdvertisementDocument = gql`
    mutation removeAdvertisement($id: Int!) {
  removeAdvertisement(id: $id)
}
    `;
export type RemoveAdvertisementMutationFn = Apollo.MutationFunction<RemoveAdvertisementMutation, RemoveAdvertisementMutationVariables>;

/**
 * __useRemoveAdvertisementMutation__
 *
 * To run a mutation, you first call `useRemoveAdvertisementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdvertisementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdvertisementMutation, { data, loading, error }] = useRemoveAdvertisementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAdvertisementMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAdvertisementMutation, RemoveAdvertisementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAdvertisementMutation, RemoveAdvertisementMutationVariables>(RemoveAdvertisementDocument, options);
      }
export type RemoveAdvertisementMutationHookResult = ReturnType<typeof useRemoveAdvertisementMutation>;
export type RemoveAdvertisementMutationResult = Apollo.MutationResult<RemoveAdvertisementMutation>;
export type RemoveAdvertisementMutationOptions = Apollo.BaseMutationOptions<RemoveAdvertisementMutation, RemoveAdvertisementMutationVariables>;
export const UpdateAdvertisementDocument = gql`
    mutation updateAdvertisement($id: Int!, $advertisement: AdvertisementInput!) {
  updateAdvertisement(id: $id, advertisement: $advertisement) {
    id
  }
}
    `;
export type UpdateAdvertisementMutationFn = Apollo.MutationFunction<UpdateAdvertisementMutation, UpdateAdvertisementMutationVariables>;

/**
 * __useUpdateAdvertisementMutation__
 *
 * To run a mutation, you first call `useUpdateAdvertisementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvertisementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvertisementMutation, { data, loading, error }] = useUpdateAdvertisementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      advertisement: // value for 'advertisement'
 *   },
 * });
 */
export function useUpdateAdvertisementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdvertisementMutation, UpdateAdvertisementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdvertisementMutation, UpdateAdvertisementMutationVariables>(UpdateAdvertisementDocument, options);
      }
export type UpdateAdvertisementMutationHookResult = ReturnType<typeof useUpdateAdvertisementMutation>;
export type UpdateAdvertisementMutationResult = Apollo.MutationResult<UpdateAdvertisementMutation>;
export type UpdateAdvertisementMutationOptions = Apollo.BaseMutationOptions<UpdateAdvertisementMutation, UpdateAdvertisementMutationVariables>;
export const CreateArticleDocument = gql`
    mutation createArticle($article: ArticleInput!) {
  createArticle(article: $article) {
    id
  }
}
    `;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      article: // value for 'article'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, options);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const GetArticleDocument = gql`
    query getArticle($id: Int!) {
  article(id: $id) {
    id
    status
    title
    author {
      id
      name
    }
    price
    startDate
    endDate
    tags
    category {
      id
      name
    }
    index
    content
    managerNote
    isEditorChoice
    isAcceptedPublish
    subPage {
      id
      title
      page {
        id
        title
      }
    }
    imageId
    bannerId
  }
}
    `;

/**
 * __useGetArticleQuery__
 *
 * To run a query within a React component, call `useGetArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleQuery(baseOptions: Apollo.QueryHookOptions<GetArticleQuery, GetArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, options);
      }
export function useGetArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleQuery, GetArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, options);
        }
export type GetArticleQueryHookResult = ReturnType<typeof useGetArticleQuery>;
export type GetArticleLazyQueryHookResult = ReturnType<typeof useGetArticleLazyQuery>;
export type GetArticleQueryResult = Apollo.QueryResult<GetArticleQuery, GetArticleQueryVariables>;
export const GetArticlesDocument = gql`
    query getArticles {
  articles {
    id
    status
    title
    author {
      id
      name
    }
    price
    startDate
    endDate
    tags
    category {
      id
      name
    }
    index
    content
    managerNote
    isEditorChoice
    isAcceptedPublish
    subPage {
      id
      title
      page {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArticlesQuery(baseOptions?: Apollo.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
      }
export function useGetArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
        }
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>;
export type GetArticlesQueryResult = Apollo.QueryResult<GetArticlesQuery, GetArticlesQueryVariables>;
export const RemoveArticleDocument = gql`
    mutation removeArticle($id: Int!) {
  removeArticle(id: $id)
}
    `;
export type RemoveArticleMutationFn = Apollo.MutationFunction<RemoveArticleMutation, RemoveArticleMutationVariables>;

/**
 * __useRemoveArticleMutation__
 *
 * To run a mutation, you first call `useRemoveArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArticleMutation, { data, loading, error }] = useRemoveArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveArticleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveArticleMutation, RemoveArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveArticleMutation, RemoveArticleMutationVariables>(RemoveArticleDocument, options);
      }
export type RemoveArticleMutationHookResult = ReturnType<typeof useRemoveArticleMutation>;
export type RemoveArticleMutationResult = Apollo.MutationResult<RemoveArticleMutation>;
export type RemoveArticleMutationOptions = Apollo.BaseMutationOptions<RemoveArticleMutation, RemoveArticleMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation updateArticle($id: Int!, $article: ArticleInput!) {
  updateArticle(id: $id, article: $article) {
    id
  }
}
    `;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      article: // value for 'article'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const CreateBackstageUserDocument = gql`
    mutation createBackstageUser($backstageUser: BackstageUserInput!) {
  createBackstageUser(backstageUser: $backstageUser) {
    id
  }
}
    `;
export type CreateBackstageUserMutationFn = Apollo.MutationFunction<CreateBackstageUserMutation, CreateBackstageUserMutationVariables>;

/**
 * __useCreateBackstageUserMutation__
 *
 * To run a mutation, you first call `useCreateBackstageUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackstageUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackstageUserMutation, { data, loading, error }] = useCreateBackstageUserMutation({
 *   variables: {
 *      backstageUser: // value for 'backstageUser'
 *   },
 * });
 */
export function useCreateBackstageUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateBackstageUserMutation, CreateBackstageUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBackstageUserMutation, CreateBackstageUserMutationVariables>(CreateBackstageUserDocument, options);
      }
export type CreateBackstageUserMutationHookResult = ReturnType<typeof useCreateBackstageUserMutation>;
export type CreateBackstageUserMutationResult = Apollo.MutationResult<CreateBackstageUserMutation>;
export type CreateBackstageUserMutationOptions = Apollo.BaseMutationOptions<CreateBackstageUserMutation, CreateBackstageUserMutationVariables>;
export const GetBackstageUserDocument = gql`
    query getBackstageUser($id: Int!) {
  backstageUser(id: $id) {
    id
    name
    email
    phone
    address
    unit
    title
    role {
      id
      name
    }
    note
    enabled
  }
}
    `;

/**
 * __useGetBackstageUserQuery__
 *
 * To run a query within a React component, call `useGetBackstageUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackstageUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackstageUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBackstageUserQuery(baseOptions: Apollo.QueryHookOptions<GetBackstageUserQuery, GetBackstageUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBackstageUserQuery, GetBackstageUserQueryVariables>(GetBackstageUserDocument, options);
      }
export function useGetBackstageUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBackstageUserQuery, GetBackstageUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBackstageUserQuery, GetBackstageUserQueryVariables>(GetBackstageUserDocument, options);
        }
export type GetBackstageUserQueryHookResult = ReturnType<typeof useGetBackstageUserQuery>;
export type GetBackstageUserLazyQueryHookResult = ReturnType<typeof useGetBackstageUserLazyQuery>;
export type GetBackstageUserQueryResult = Apollo.QueryResult<GetBackstageUserQuery, GetBackstageUserQueryVariables>;
export const GetBackstageUsersDocument = gql`
    query getBackstageUsers {
  backstageUsers {
    id
    name
    phone
    role {
      id
      name
    }
    enabled
  }
}
    `;

/**
 * __useGetBackstageUsersQuery__
 *
 * To run a query within a React component, call `useGetBackstageUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackstageUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackstageUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBackstageUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetBackstageUsersQuery, GetBackstageUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBackstageUsersQuery, GetBackstageUsersQueryVariables>(GetBackstageUsersDocument, options);
      }
export function useGetBackstageUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBackstageUsersQuery, GetBackstageUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBackstageUsersQuery, GetBackstageUsersQueryVariables>(GetBackstageUsersDocument, options);
        }
export type GetBackstageUsersQueryHookResult = ReturnType<typeof useGetBackstageUsersQuery>;
export type GetBackstageUsersLazyQueryHookResult = ReturnType<typeof useGetBackstageUsersLazyQuery>;
export type GetBackstageUsersQueryResult = Apollo.QueryResult<GetBackstageUsersQuery, GetBackstageUsersQueryVariables>;
export const GetMeDocument = gql`
    query getMe {
  me {
    id
    googleId
    picture
    email
    name
    role {
      permission
      page
      client
      category
      tag
      uploadArticle
      uploadAdvertisement
      uploadVideo
      publishArticle
      publishAdvertisement
      publishVideo
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const RemoveBackstageUserDocument = gql`
    mutation removeBackstageUser($id: Int!) {
  removeBackstageUser(id: $id)
}
    `;
export type RemoveBackstageUserMutationFn = Apollo.MutationFunction<RemoveBackstageUserMutation, RemoveBackstageUserMutationVariables>;

/**
 * __useRemoveBackstageUserMutation__
 *
 * To run a mutation, you first call `useRemoveBackstageUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBackstageUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBackstageUserMutation, { data, loading, error }] = useRemoveBackstageUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBackstageUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBackstageUserMutation, RemoveBackstageUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBackstageUserMutation, RemoveBackstageUserMutationVariables>(RemoveBackstageUserDocument, options);
      }
export type RemoveBackstageUserMutationHookResult = ReturnType<typeof useRemoveBackstageUserMutation>;
export type RemoveBackstageUserMutationResult = Apollo.MutationResult<RemoveBackstageUserMutation>;
export type RemoveBackstageUserMutationOptions = Apollo.BaseMutationOptions<RemoveBackstageUserMutation, RemoveBackstageUserMutationVariables>;
export const UpdateBackstageUserDocument = gql`
    mutation updateBackstageUser($id: Int!, $backstageUser: BackstageUserInput!) {
  updateBackstageUser(id: $id, backstageUser: $backstageUser) {
    id
  }
}
    `;
export type UpdateBackstageUserMutationFn = Apollo.MutationFunction<UpdateBackstageUserMutation, UpdateBackstageUserMutationVariables>;

/**
 * __useUpdateBackstageUserMutation__
 *
 * To run a mutation, you first call `useUpdateBackstageUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBackstageUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBackstageUserMutation, { data, loading, error }] = useUpdateBackstageUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      backstageUser: // value for 'backstageUser'
 *   },
 * });
 */
export function useUpdateBackstageUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBackstageUserMutation, UpdateBackstageUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBackstageUserMutation, UpdateBackstageUserMutationVariables>(UpdateBackstageUserDocument, options);
      }
export type UpdateBackstageUserMutationHookResult = ReturnType<typeof useUpdateBackstageUserMutation>;
export type UpdateBackstageUserMutationResult = Apollo.MutationResult<UpdateBackstageUserMutation>;
export type UpdateBackstageUserMutationOptions = Apollo.BaseMutationOptions<UpdateBackstageUserMutation, UpdateBackstageUserMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($category: CategoryInput!) {
  createCategory(category: $category) {
    id
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const GetCategoryDocument = gql`
    query getCategory($id: Int!) {
  category(id: $id) {
    id
    name
    index
    enabled
  }
}
    `;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
      }
export function useGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
        }
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>;
export type GetCategoryQueryResult = Apollo.QueryResult<GetCategoryQuery, GetCategoryQueryVariables>;
export const GetCategoriesDocument = gql`
    query getCategories {
  categories {
    id
    name
    index
    enabled
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const RemoveCategoryDocument = gql`
    mutation removeCategory($id: Int!) {
  removeCategory(id: $id)
}
    `;
export type RemoveCategoryMutationFn = Apollo.MutationFunction<RemoveCategoryMutation, RemoveCategoryMutationVariables>;

/**
 * __useRemoveCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCategoryMutation, { data, loading, error }] = useRemoveCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCategoryMutation, RemoveCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCategoryMutation, RemoveCategoryMutationVariables>(RemoveCategoryDocument, options);
      }
export type RemoveCategoryMutationHookResult = ReturnType<typeof useRemoveCategoryMutation>;
export type RemoveCategoryMutationResult = Apollo.MutationResult<RemoveCategoryMutation>;
export type RemoveCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveCategoryMutation, RemoveCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($id: Int!, $category: CategoryInput!) {
  updateCategory(id: $id, category: $category) {
    id
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const CreateClientDocument = gql`
    mutation createClient($client: ClientInput!) {
  createClient(client: $client) {
    id
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      client: // value for 'client'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const GetClientDocument = gql`
    query getClient($id: Int!) {
  client(id: $id) {
    id
    name
    type
    address
    taxId
    phone
    contactName
    contactTitle
    contactUnit
    contactItem
    contactPhone
    contactEmail
    note
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientQuery(baseOptions: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;
export const GetClientsDocument = gql`
    query getClients {
  clients {
    id
    name
    type
    phone
    contactName
    contactPhone
    contactEmail
  }
}
    `;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const RemoveClientDocument = gql`
    mutation removeClient($id: Int!) {
  removeClient(id: $id)
}
    `;
export type RemoveClientMutationFn = Apollo.MutationFunction<RemoveClientMutation, RemoveClientMutationVariables>;

/**
 * __useRemoveClientMutation__
 *
 * To run a mutation, you first call `useRemoveClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientMutation, { data, loading, error }] = useRemoveClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClientMutation, RemoveClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClientMutation, RemoveClientMutationVariables>(RemoveClientDocument, options);
      }
export type RemoveClientMutationHookResult = ReturnType<typeof useRemoveClientMutation>;
export type RemoveClientMutationResult = Apollo.MutationResult<RemoveClientMutation>;
export type RemoveClientMutationOptions = Apollo.BaseMutationOptions<RemoveClientMutation, RemoveClientMutationVariables>;
export const UpdateClientDocument = gql`
    mutation updateClient($id: Int!, $client: ClientInput!) {
  updateClient(id: $id, client: $client) {
    id
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      client: // value for 'client'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const CreateFilterCategoryDocument = gql`
    mutation createFilterCategory($filterCategory: FilterCategoryInput!) {
  createFilterCategory(filterCategory: $filterCategory) {
    id
  }
}
    `;
export type CreateFilterCategoryMutationFn = Apollo.MutationFunction<CreateFilterCategoryMutation, CreateFilterCategoryMutationVariables>;

/**
 * __useCreateFilterCategoryMutation__
 *
 * To run a mutation, you first call `useCreateFilterCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilterCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilterCategoryMutation, { data, loading, error }] = useCreateFilterCategoryMutation({
 *   variables: {
 *      filterCategory: // value for 'filterCategory'
 *   },
 * });
 */
export function useCreateFilterCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateFilterCategoryMutation, CreateFilterCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFilterCategoryMutation, CreateFilterCategoryMutationVariables>(CreateFilterCategoryDocument, options);
      }
export type CreateFilterCategoryMutationHookResult = ReturnType<typeof useCreateFilterCategoryMutation>;
export type CreateFilterCategoryMutationResult = Apollo.MutationResult<CreateFilterCategoryMutation>;
export type CreateFilterCategoryMutationOptions = Apollo.BaseMutationOptions<CreateFilterCategoryMutation, CreateFilterCategoryMutationVariables>;
export const GetFilterCategoryDocument = gql`
    query getFilterCategory($id: Int!) {
  filterCategory(id: $id) {
    id
    name
    index
    enabled
    filterItems {
      id
      name
      index
      enabled
    }
  }
}
    `;

/**
 * __useGetFilterCategoryQuery__
 *
 * To run a query within a React component, call `useGetFilterCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilterCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetFilterCategoryQuery, GetFilterCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterCategoryQuery, GetFilterCategoryQueryVariables>(GetFilterCategoryDocument, options);
      }
export function useGetFilterCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterCategoryQuery, GetFilterCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterCategoryQuery, GetFilterCategoryQueryVariables>(GetFilterCategoryDocument, options);
        }
export type GetFilterCategoryQueryHookResult = ReturnType<typeof useGetFilterCategoryQuery>;
export type GetFilterCategoryLazyQueryHookResult = ReturnType<typeof useGetFilterCategoryLazyQuery>;
export type GetFilterCategoryQueryResult = Apollo.QueryResult<GetFilterCategoryQuery, GetFilterCategoryQueryVariables>;
export const GetFilterCategoriesDocument = gql`
    query getFilterCategories {
  filterCategories {
    id
    name
    index
    enabled
  }
}
    `;

/**
 * __useGetFilterCategoriesQuery__
 *
 * To run a query within a React component, call `useGetFilterCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>(GetFilterCategoriesDocument, options);
      }
export function useGetFilterCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>(GetFilterCategoriesDocument, options);
        }
export type GetFilterCategoriesQueryHookResult = ReturnType<typeof useGetFilterCategoriesQuery>;
export type GetFilterCategoriesLazyQueryHookResult = ReturnType<typeof useGetFilterCategoriesLazyQuery>;
export type GetFilterCategoriesQueryResult = Apollo.QueryResult<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>;
export const RemoveFilterCategoryDocument = gql`
    mutation removeFilterCategory($id: Int!) {
  removeFilterCategory(id: $id)
}
    `;
export type RemoveFilterCategoryMutationFn = Apollo.MutationFunction<RemoveFilterCategoryMutation, RemoveFilterCategoryMutationVariables>;

/**
 * __useRemoveFilterCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveFilterCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFilterCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFilterCategoryMutation, { data, loading, error }] = useRemoveFilterCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFilterCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFilterCategoryMutation, RemoveFilterCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFilterCategoryMutation, RemoveFilterCategoryMutationVariables>(RemoveFilterCategoryDocument, options);
      }
export type RemoveFilterCategoryMutationHookResult = ReturnType<typeof useRemoveFilterCategoryMutation>;
export type RemoveFilterCategoryMutationResult = Apollo.MutationResult<RemoveFilterCategoryMutation>;
export type RemoveFilterCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveFilterCategoryMutation, RemoveFilterCategoryMutationVariables>;
export const UpdateFilterCategoryDocument = gql`
    mutation updateFilterCategory($id: Int!, $filterCategory: FilterCategoryInput!) {
  updateFilterCategory(id: $id, filterCategory: $filterCategory) {
    id
  }
}
    `;
export type UpdateFilterCategoryMutationFn = Apollo.MutationFunction<UpdateFilterCategoryMutation, UpdateFilterCategoryMutationVariables>;

/**
 * __useUpdateFilterCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateFilterCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFilterCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFilterCategoryMutation, { data, loading, error }] = useUpdateFilterCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      filterCategory: // value for 'filterCategory'
 *   },
 * });
 */
export function useUpdateFilterCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFilterCategoryMutation, UpdateFilterCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFilterCategoryMutation, UpdateFilterCategoryMutationVariables>(UpdateFilterCategoryDocument, options);
      }
export type UpdateFilterCategoryMutationHookResult = ReturnType<typeof useUpdateFilterCategoryMutation>;
export type UpdateFilterCategoryMutationResult = Apollo.MutationResult<UpdateFilterCategoryMutation>;
export type UpdateFilterCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateFilterCategoryMutation, UpdateFilterCategoryMutationVariables>;
export const CreateFilterItemDocument = gql`
    mutation createFilterItem($filterItem: FilterItemInput!) {
  createFilterItem(filterItem: $filterItem) {
    id
  }
}
    `;
export type CreateFilterItemMutationFn = Apollo.MutationFunction<CreateFilterItemMutation, CreateFilterItemMutationVariables>;

/**
 * __useCreateFilterItemMutation__
 *
 * To run a mutation, you first call `useCreateFilterItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilterItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilterItemMutation, { data, loading, error }] = useCreateFilterItemMutation({
 *   variables: {
 *      filterItem: // value for 'filterItem'
 *   },
 * });
 */
export function useCreateFilterItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateFilterItemMutation, CreateFilterItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFilterItemMutation, CreateFilterItemMutationVariables>(CreateFilterItemDocument, options);
      }
export type CreateFilterItemMutationHookResult = ReturnType<typeof useCreateFilterItemMutation>;
export type CreateFilterItemMutationResult = Apollo.MutationResult<CreateFilterItemMutation>;
export type CreateFilterItemMutationOptions = Apollo.BaseMutationOptions<CreateFilterItemMutation, CreateFilterItemMutationVariables>;
export const GetFilterItemDocument = gql`
    query getFilterItem($id: Int!) {
  filterItem(id: $id) {
    id
    name
    index
    enabled
    filterCategory {
      id
    }
  }
}
    `;

/**
 * __useGetFilterItemQuery__
 *
 * To run a query within a React component, call `useGetFilterItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilterItemQuery(baseOptions: Apollo.QueryHookOptions<GetFilterItemQuery, GetFilterItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterItemQuery, GetFilterItemQueryVariables>(GetFilterItemDocument, options);
      }
export function useGetFilterItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterItemQuery, GetFilterItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterItemQuery, GetFilterItemQueryVariables>(GetFilterItemDocument, options);
        }
export type GetFilterItemQueryHookResult = ReturnType<typeof useGetFilterItemQuery>;
export type GetFilterItemLazyQueryHookResult = ReturnType<typeof useGetFilterItemLazyQuery>;
export type GetFilterItemQueryResult = Apollo.QueryResult<GetFilterItemQuery, GetFilterItemQueryVariables>;
export const RemoveFilterItemDocument = gql`
    mutation removeFilterItem($id: Int!) {
  removeFilterItem(id: $id)
}
    `;
export type RemoveFilterItemMutationFn = Apollo.MutationFunction<RemoveFilterItemMutation, RemoveFilterItemMutationVariables>;

/**
 * __useRemoveFilterItemMutation__
 *
 * To run a mutation, you first call `useRemoveFilterItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFilterItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFilterItemMutation, { data, loading, error }] = useRemoveFilterItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFilterItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFilterItemMutation, RemoveFilterItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFilterItemMutation, RemoveFilterItemMutationVariables>(RemoveFilterItemDocument, options);
      }
export type RemoveFilterItemMutationHookResult = ReturnType<typeof useRemoveFilterItemMutation>;
export type RemoveFilterItemMutationResult = Apollo.MutationResult<RemoveFilterItemMutation>;
export type RemoveFilterItemMutationOptions = Apollo.BaseMutationOptions<RemoveFilterItemMutation, RemoveFilterItemMutationVariables>;
export const UpdateFilterItemDocument = gql`
    mutation updateFilterItem($id: Int!, $filterItem: FilterItemInput!) {
  updateFilterItem(id: $id, filterItem: $filterItem) {
    id
  }
}
    `;
export type UpdateFilterItemMutationFn = Apollo.MutationFunction<UpdateFilterItemMutation, UpdateFilterItemMutationVariables>;

/**
 * __useUpdateFilterItemMutation__
 *
 * To run a mutation, you first call `useUpdateFilterItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFilterItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFilterItemMutation, { data, loading, error }] = useUpdateFilterItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      filterItem: // value for 'filterItem'
 *   },
 * });
 */
export function useUpdateFilterItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFilterItemMutation, UpdateFilterItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFilterItemMutation, UpdateFilterItemMutationVariables>(UpdateFilterItemDocument, options);
      }
export type UpdateFilterItemMutationHookResult = ReturnType<typeof useUpdateFilterItemMutation>;
export type UpdateFilterItemMutationResult = Apollo.MutationResult<UpdateFilterItemMutation>;
export type UpdateFilterItemMutationOptions = Apollo.BaseMutationOptions<UpdateFilterItemMutation, UpdateFilterItemMutationVariables>;
export const GetHomeDocument = gql`
    query getHome {
  articles {
    id
    status
  }
  advertisements {
    id
    status
  }
  videos {
    id
    status
  }
}
    `;

/**
 * __useGetHomeQuery__
 *
 * To run a query within a React component, call `useGetHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeQuery(baseOptions?: Apollo.QueryHookOptions<GetHomeQuery, GetHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomeQuery, GetHomeQueryVariables>(GetHomeDocument, options);
      }
export function useGetHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeQuery, GetHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomeQuery, GetHomeQueryVariables>(GetHomeDocument, options);
        }
export type GetHomeQueryHookResult = ReturnType<typeof useGetHomeQuery>;
export type GetHomeLazyQueryHookResult = ReturnType<typeof useGetHomeLazyQuery>;
export type GetHomeQueryResult = Apollo.QueryResult<GetHomeQuery, GetHomeQueryVariables>;
export const CreatePageDocument = gql`
    mutation createPage($page: PageInput!) {
  createPage(page: $page) {
    id
  }
}
    `;
export type CreatePageMutationFn = Apollo.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCreatePageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, options);
      }
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const GetPageDocument = gql`
    query getPage($id: Int!) {
  page(id: $id) {
    id
    title
    articleGridLayout
    advertisementGridLayout
    enabled
    subPages {
      id
      title
      articleGridLayout
      advertisementGridLayout
      enabled
    }
  }
}
    `;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const GetPagesDocument = gql`
    query getPages {
  pages {
    id
    title
    subPages {
      id
      title
    }
    articleGridLayout
    advertisementGridLayout
    enabled
  }
}
    `;

/**
 * __useGetPagesQuery__
 *
 * To run a query within a React component, call `useGetPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPagesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, options);
      }
export function useGetPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, options);
        }
export type GetPagesQueryHookResult = ReturnType<typeof useGetPagesQuery>;
export type GetPagesLazyQueryHookResult = ReturnType<typeof useGetPagesLazyQuery>;
export type GetPagesQueryResult = Apollo.QueryResult<GetPagesQuery, GetPagesQueryVariables>;
export const RemovePageDocument = gql`
    mutation removePage($id: Int!) {
  removePage(id: $id)
}
    `;
export type RemovePageMutationFn = Apollo.MutationFunction<RemovePageMutation, RemovePageMutationVariables>;

/**
 * __useRemovePageMutation__
 *
 * To run a mutation, you first call `useRemovePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePageMutation, { data, loading, error }] = useRemovePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePageMutation(baseOptions?: Apollo.MutationHookOptions<RemovePageMutation, RemovePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePageMutation, RemovePageMutationVariables>(RemovePageDocument, options);
      }
export type RemovePageMutationHookResult = ReturnType<typeof useRemovePageMutation>;
export type RemovePageMutationResult = Apollo.MutationResult<RemovePageMutation>;
export type RemovePageMutationOptions = Apollo.BaseMutationOptions<RemovePageMutation, RemovePageMutationVariables>;
export const UpdatePageDocument = gql`
    mutation updatePage($id: Int!, $page: PageInput!) {
  updatePage(id: $id, page: $page) {
    id
  }
}
    `;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const CreateRoleDocument = gql`
    mutation createRole($role: RoleInput!) {
  createRole(role: $role) {
    id
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($id: Int!) {
  role(id: $id) {
    id
    name
    permission
    page
    client
    category
    tag
    uploadArticle
    uploadAdvertisement
    uploadVideo
    publishArticle
    publishAdvertisement
    publishVideo
    enabled
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetRolesDocument = gql`
    query getRoles {
  roles {
    id
    name
    permission
    page
    client
    category
    tag
    uploadArticle
    uploadAdvertisement
    uploadVideo
    publishArticle
    publishAdvertisement
    publishVideo
    enabled
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const RemoveRoleDocument = gql`
    mutation removeRole($id: Int!) {
  removeRole(id: $id)
}
    `;
export type RemoveRoleMutationFn = Apollo.MutationFunction<RemoveRoleMutation, RemoveRoleMutationVariables>;

/**
 * __useRemoveRoleMutation__
 *
 * To run a mutation, you first call `useRemoveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleMutation, { data, loading, error }] = useRemoveRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRoleMutation, RemoveRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRoleMutation, RemoveRoleMutationVariables>(RemoveRoleDocument, options);
      }
export type RemoveRoleMutationHookResult = ReturnType<typeof useRemoveRoleMutation>;
export type RemoveRoleMutationResult = Apollo.MutationResult<RemoveRoleMutation>;
export type RemoveRoleMutationOptions = Apollo.BaseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($id: Int!, $role: RoleInput!) {
  updateRole(id: $id, role: $role) {
    id
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const CreateSubPageDocument = gql`
    mutation createSubPage($subPage: SubPageInput!) {
  createSubPage(subPage: $subPage) {
    id
  }
}
    `;
export type CreateSubPageMutationFn = Apollo.MutationFunction<CreateSubPageMutation, CreateSubPageMutationVariables>;

/**
 * __useCreateSubPageMutation__
 *
 * To run a mutation, you first call `useCreateSubPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubPageMutation, { data, loading, error }] = useCreateSubPageMutation({
 *   variables: {
 *      subPage: // value for 'subPage'
 *   },
 * });
 */
export function useCreateSubPageMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubPageMutation, CreateSubPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubPageMutation, CreateSubPageMutationVariables>(CreateSubPageDocument, options);
      }
export type CreateSubPageMutationHookResult = ReturnType<typeof useCreateSubPageMutation>;
export type CreateSubPageMutationResult = Apollo.MutationResult<CreateSubPageMutation>;
export type CreateSubPageMutationOptions = Apollo.BaseMutationOptions<CreateSubPageMutation, CreateSubPageMutationVariables>;
export const GetSubPageDocument = gql`
    query getSubPage($id: Int!) {
  subPage(id: $id) {
    id
    title
    articleGridLayout
    advertisementGridLayout
    enabled
    page {
      id
    }
  }
}
    `;

/**
 * __useGetSubPageQuery__
 *
 * To run a query within a React component, call `useGetSubPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubPageQuery(baseOptions: Apollo.QueryHookOptions<GetSubPageQuery, GetSubPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubPageQuery, GetSubPageQueryVariables>(GetSubPageDocument, options);
      }
export function useGetSubPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubPageQuery, GetSubPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubPageQuery, GetSubPageQueryVariables>(GetSubPageDocument, options);
        }
export type GetSubPageQueryHookResult = ReturnType<typeof useGetSubPageQuery>;
export type GetSubPageLazyQueryHookResult = ReturnType<typeof useGetSubPageLazyQuery>;
export type GetSubPageQueryResult = Apollo.QueryResult<GetSubPageQuery, GetSubPageQueryVariables>;
export const RemoveSubPageDocument = gql`
    mutation removeSubPage($id: Int!) {
  removeSubPage(id: $id)
}
    `;
export type RemoveSubPageMutationFn = Apollo.MutationFunction<RemoveSubPageMutation, RemoveSubPageMutationVariables>;

/**
 * __useRemoveSubPageMutation__
 *
 * To run a mutation, you first call `useRemoveSubPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubPageMutation, { data, loading, error }] = useRemoveSubPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSubPageMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSubPageMutation, RemoveSubPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSubPageMutation, RemoveSubPageMutationVariables>(RemoveSubPageDocument, options);
      }
export type RemoveSubPageMutationHookResult = ReturnType<typeof useRemoveSubPageMutation>;
export type RemoveSubPageMutationResult = Apollo.MutationResult<RemoveSubPageMutation>;
export type RemoveSubPageMutationOptions = Apollo.BaseMutationOptions<RemoveSubPageMutation, RemoveSubPageMutationVariables>;
export const UpdateSubPageDocument = gql`
    mutation updateSubPage($id: Int!, $subPage: SubPageInput!) {
  updateSubPage(id: $id, subPage: $subPage) {
    id
  }
}
    `;
export type UpdateSubPageMutationFn = Apollo.MutationFunction<UpdateSubPageMutation, UpdateSubPageMutationVariables>;

/**
 * __useUpdateSubPageMutation__
 *
 * To run a mutation, you first call `useUpdateSubPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubPageMutation, { data, loading, error }] = useUpdateSubPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      subPage: // value for 'subPage'
 *   },
 * });
 */
export function useUpdateSubPageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubPageMutation, UpdateSubPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubPageMutation, UpdateSubPageMutationVariables>(UpdateSubPageDocument, options);
      }
export type UpdateSubPageMutationHookResult = ReturnType<typeof useUpdateSubPageMutation>;
export type UpdateSubPageMutationResult = Apollo.MutationResult<UpdateSubPageMutation>;
export type UpdateSubPageMutationOptions = Apollo.BaseMutationOptions<UpdateSubPageMutation, UpdateSubPageMutationVariables>;
export const GetUserDocument = gql`
    query getUser($id: Int!) {
  user(id: $id) {
    id
    email
    enabled
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  users {
    id
    email
    enabled
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($id: Int!) {
  removeUser(id: $id)
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: Int!, $user: UserInput!) {
  updateUser(id: $id, user: $user) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateVideoDocument = gql`
    mutation createVideo($video: VideoInput!) {
  createVideo(video: $video) {
    id
  }
}
    `;
export type CreateVideoMutationFn = Apollo.MutationFunction<CreateVideoMutation, CreateVideoMutationVariables>;

/**
 * __useCreateVideoMutation__
 *
 * To run a mutation, you first call `useCreateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoMutation, { data, loading, error }] = useCreateVideoMutation({
 *   variables: {
 *      video: // value for 'video'
 *   },
 * });
 */
export function useCreateVideoMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoMutation, CreateVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoMutation, CreateVideoMutationVariables>(CreateVideoDocument, options);
      }
export type CreateVideoMutationHookResult = ReturnType<typeof useCreateVideoMutation>;
export type CreateVideoMutationResult = Apollo.MutationResult<CreateVideoMutation>;
export type CreateVideoMutationOptions = Apollo.BaseMutationOptions<CreateVideoMutation, CreateVideoMutationVariables>;
export const GetVideoDocument = gql`
    query getVideo($id: Int!) {
  video(id: $id) {
    id
    status
    page {
      id
      title
    }
    index
    title
    category {
      id
      name
    }
    author {
      id
      name
    }
    startDate
    endDate
    fileId
    note
    isAcceptedPublish
  }
}
    `;

/**
 * __useGetVideoQuery__
 *
 * To run a query within a React component, call `useGetVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVideoQuery(baseOptions: Apollo.QueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, options);
      }
export function useGetVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, options);
        }
export type GetVideoQueryHookResult = ReturnType<typeof useGetVideoQuery>;
export type GetVideoLazyQueryHookResult = ReturnType<typeof useGetVideoLazyQuery>;
export type GetVideoQueryResult = Apollo.QueryResult<GetVideoQuery, GetVideoQueryVariables>;
export const GetVideosDocument = gql`
    query getVideos {
  videos {
    id
    title
    author {
      id
      email
    }
    page {
      id
      title
    }
    startDate
    status
  }
}
    `;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVideosQuery(baseOptions?: Apollo.QueryHookOptions<GetVideosQuery, GetVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
      }
export function useGetVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
        }
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<typeof useGetVideosLazyQuery>;
export type GetVideosQueryResult = Apollo.QueryResult<GetVideosQuery, GetVideosQueryVariables>;
export const RemoveVideoDocument = gql`
    mutation removeVideo($id: Int!) {
  removeVideo(id: $id)
}
    `;
export type RemoveVideoMutationFn = Apollo.MutationFunction<RemoveVideoMutation, RemoveVideoMutationVariables>;

/**
 * __useRemoveVideoMutation__
 *
 * To run a mutation, you first call `useRemoveVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVideoMutation, { data, loading, error }] = useRemoveVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveVideoMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVideoMutation, RemoveVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVideoMutation, RemoveVideoMutationVariables>(RemoveVideoDocument, options);
      }
export type RemoveVideoMutationHookResult = ReturnType<typeof useRemoveVideoMutation>;
export type RemoveVideoMutationResult = Apollo.MutationResult<RemoveVideoMutation>;
export type RemoveVideoMutationOptions = Apollo.BaseMutationOptions<RemoveVideoMutation, RemoveVideoMutationVariables>;
export const UpdateVideoDocument = gql`
    mutation updateVideo($id: Int!, $video: VideoInput!) {
  updateVideo(id: $id, video: $video) {
    id
  }
}
    `;
export type UpdateVideoMutationFn = Apollo.MutationFunction<UpdateVideoMutation, UpdateVideoMutationVariables>;

/**
 * __useUpdateVideoMutation__
 *
 * To run a mutation, you first call `useUpdateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoMutation, { data, loading, error }] = useUpdateVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      video: // value for 'video'
 *   },
 * });
 */
export function useUpdateVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoMutation, UpdateVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoMutation, UpdateVideoMutationVariables>(UpdateVideoDocument, options);
      }
export type UpdateVideoMutationHookResult = ReturnType<typeof useUpdateVideoMutation>;
export type UpdateVideoMutationResult = Apollo.MutationResult<UpdateVideoMutation>;
export type UpdateVideoMutationOptions = Apollo.BaseMutationOptions<UpdateVideoMutation, UpdateVideoMutationVariables>;