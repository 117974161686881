import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {OrderList, RoleList} from "../common/TestData";
import {useParams} from "react-router-dom";
import {
    useCreateCategoryMutation,
    useGetCategoryLazyQuery,
    useGetVideoLazyQuery, useRemoveCategoryMutation,
    useUpdateCategoryMutation,
    useUpdateVideoMutation
} from "../generated/graphql";
import {useCategories, usePages} from "../common/Utils";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetCategoryLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const [updateCategory] = useUpdateCategoryMutation();
    const [createCategory] = useCreateCategoryMutation();
    const [removeCategory] = useRemoveCategoryMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (category, { resetForm}) => {
            try {
                if (isNew) {
                    const newCategory = await createCategory({
                        variables: {
                            category,
                        }
                    });
                    NotificationPopup.success(`新增完成`);
                    navigate(`/category/${newCategory.data?.createCategory.id}`);
                    window.location.reload();
                }
                else {
                    await updateCategory({
                        variables: {
                            id: parseInt(id as string),
                            category: omit(category, ['id']),
                        }
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`修改發生問題`);
                console.error(e);
            }
        },[data]
    );
    const onRemove = async () => {
        await removeCategory({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getCategories"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate("/category");
    }
    const columns = [
        {name: "name", label: "分類名稱", value: data?.category.name},
        {name: "index", label: "排序", type: InputType.select, options: OrderList, value: data?.category.index},
        {name: "enabled", label: "是否啟用", type: InputType.switch, value: data?.category.enabled},
    ]

    return (
        <Page title={`${isNew ? "新增" : "編輯"}分類`}>
            {(isNew || data?.category) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
            />}
        </Page>
    )
}