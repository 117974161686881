import {Box, Grid} from "@mui/material";
import {Moon} from "react-feather";

export default ({title, count, url}) => {

    return <a href={url} style={{textDecoration: "none", color: "black"}}><Grid
        alignItems="center"
        justifyContent={"center"}
        display="flex"
        flexDirection="row"
        style={{width: 350, height: 150, border: "1pt solid black"}}>
        <Grid item>
            <Moon/>
        </Grid>
        <Grid item container display="flex" flexDirection="column" alignContent="center" justifyContent={"center"} style={{width: 200, marginLeft: 50}}>
            <Grid item><b style={{fontSize: 32}}>{title}</b></Grid>
            <Grid item style={{fontSize: 24, display: "flex", justifyContent: "center"}}>{count} 則未處理</Grid>
        </Grid>
    </Grid></a>
}