import React, {useState} from "react";
import "./App.css";
import {useRoutes} from "react-router-dom";
import {ThemeProvider, Box, Grid} from "@mui/material";
import theme from "./theme/Theme";
import {ErrorBoundary} from "./common/ErrorBoundary";
import routes from "./routes";
import {makeStyles} from "@mui/styles";
import {GoogleOAuthProvider} from "@react-oauth/google";

const Inner = () => {
    const routing = useRoutes(routes);
    return routing;
}
const useStyles = makeStyles((theme: any) => ({

}))

const App = () => {
    const classes = useStyles();
    return (
        <GoogleOAuthProvider clientId="91884652958-kjd99echj533do32vi7m1ef53t53fr1d.apps.googleusercontent.com">
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <Inner/>
                </ErrorBoundary>
            </ThemeProvider>
        </GoogleOAuthProvider>
    );
};

export default App;
