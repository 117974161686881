import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {OrderList, RoleList} from "../common/TestData";
import {useLocation, useParams} from "react-router-dom";
import {
    useCreateFilterItemMutation,
    useGetFilterItemLazyQuery, useRemoveFilterItemMutation,
    useUpdateFilterItemMutation,
} from "../generated/graphql";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const filterCategoryId = queryParams.get('searchId');
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetFilterItemLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const [updateFilterItem] = useUpdateFilterItemMutation();
    const [createFilterItem] = useCreateFilterItemMutation();
    const [removeFilterItem] = useRemoveFilterItemMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (filterItem, { resetForm}) => {
            try {
                if (isNew) {
                    const newFilterItem = await createFilterItem({
                        variables: {
                            filterItem: {
                                ...filterItem,
                                filterCategoryId: parseInt(filterCategoryId as string),
                            },
                        }
                    });
                    NotificationPopup.success(`新增完成`);
                    navigate(`/search-item-view/${newFilterItem.data?.createFilterItem.id}`);
                    window.location.reload();
                }
                else {
                    await updateFilterItem({
                        variables: {
                            id: parseInt(id as string),
                            filterItem: omit(filterItem, ['id']),
                        }
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`修改發生問題`);
                console.error(e);
            }
        },[data]
    );
    const onRemove = async () => {
        await removeFilterItem({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getFilterItems"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate(`/search-item/${data?.filterItem?.filterCategory?.id}`);
    }
    const columns = [
        {name: "name", label: "分類名稱", value: data?.filterItem.name},
        {name: "index", label: "排序", type: InputType.select, options: OrderList, value: data?.filterItem.index},
        {name: "enabled", label: "是否啟用", type: InputType.switch, value: data?.filterItem.enabled},
    ]

    return (
        <Page title={`${isNew ? "新增" : "編輯"}子分類`}>
            {(isNew || data?.filterItem) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
            />}
        </Page>
    )
}