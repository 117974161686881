import Page from "../common/Page";
import {DataGridPro, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import {BooleanValueGetter, DataGridLocale, DateValueGetter, ViewColumn} from "../common/Utils";
import {ArticleList, RoleList, UserList} from "../common/TestData";
import {useState} from "react";
import {StatusMap} from "../common/Constant";
import {useGetBackstageUsersQuery} from "../generated/graphql";
import {useNavigate} from "react-router";

const columns = [
    {field: "id", headerName: "ID", width: 100},
    {field: "name", headerName: "帳號名稱", width: 200},
    {field: "role", headerName: "角色權限", width: 100, valueGetter: (params:GridValueGetterParams) =>
            params.value.name},
    {field: "enabled", headerName: "狀態", width: 100, valueGetter: (params)=>
            params.value ? "啟用中" : "停用中"},
    ViewColumn('/user'),
]
export default () => {
    const navigate = useNavigate();
    const {data} = useGetBackstageUsersQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"asc"}]);
    return <Page title={"帳號設定"}>
        <Button onClick={()=>navigate("/user/new")}>
            新增
        </Button>
        <Box style={{margin: 30, height: "100vh"}}>
            <DataGridPro
                localeText={DataGridLocale}
                autoHeight={true}
                rows={data ? data.backstageUsers : []}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(sortModel)=> {
                    setSortModel(sortModel);
                }}
            />
        </Box>
    </Page>
}