import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {useParams} from "react-router-dom";
import {
    useGetUserLazyQuery,
    useGetVideoLazyQuery,
    useUpdateUserMutation,
    useUpdateVideoMutation
} from "../generated/graphql";
import {useCategories, usePages} from "../common/Utils";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";


export default () => {
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetUserLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const [updateUser] = useUpdateUserMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (user, { resetForm}) => {
            try {
                await updateUser({
                    variables: {
                        id: parseInt(id as string),
                        user: omit(user, ['id']),
                    }
                });
                NotificationPopup.success(`修改完成`);
                window.location.reload();
            }
            catch (e) {
                NotificationPopup.error(`修改發生問題`);
                console.error(e);
            }
        },[data]
    );
    const columns = [
        {name: "email", label: "帳號名稱", value: data?.user.email},
        {name: "enabled", label: "是否啟用", type: InputType.switch, value: data?.user.enabled},
    ]
    return (
        <Page title={"編輯會員"}>
            {(isNew || data?.user) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
            />}
        </Page>
    )
}