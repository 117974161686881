import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {PageTypeList, RoleList} from "../common/TestData";
import {useLocation, useParams} from "react-router-dom";
import {
    ArticleGridLayout, useCreateSubPageMutation,
    useGetPageLazyQuery, useGetSubPageLazyQuery,
    useGetVideoLazyQuery, useRemoveSubPageMutation,
    useUpdatePageMutation, useUpdateSubPageMutation,
    useUpdateVideoMutation
} from "../generated/graphql";
import {useCategories, usePages} from "../common/Utils";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {AdvertisementGridLayoutOptions, ArticleGridLayoutOptions} from "../common/Constant";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const pageId = queryParams.get('pageId');
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetSubPageLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const [updateSubPage] = useUpdateSubPageMutation();
    const [createSubPage] = useCreateSubPageMutation();
    const [removeSubPage] = useRemoveSubPageMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (subPage, {resetForm}) => {
            try {
                if (isNew) {
                    const newSubPage = await createSubPage({
                        variables: {subPage: {
                            ...subPage,
                            pageId: parseInt(pageId as string),
                        }}
                    });
                    NotificationPopup.success(`新增完成`);
                    navigate(`/subpage/${newSubPage.data?.createSubPage.id}`);
                    window.location.reload();
                }
                else {
                    await updateSubPage({
                        variables: {
                            id: parseInt(id as string),
                            subPage: omit(subPage, ['id']),
                        },
                        refetchQueries: ["getSubPage"],
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`發生問題`);
                console.error(e);
            }
        },[data]
    );
    const onRemove = async () => {
        await removeSubPage({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getPage"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate(`/page-subpage/${data?.subPage?.page?.id}`);
    }
    const columns = [
        {name: "title", label: "頁面名稱", value: data?.subPage.title},
        {name: "articleGridLayout", label: "頁面版型", type: InputType.select, options: ArticleGridLayoutOptions, value: data?.subPage.articleGridLayout},
        {name: "advertisementGridLayout", label: "廣告版型", type: InputType.select, options: AdvertisementGridLayoutOptions, value: data?.subPage.advertisementGridLayout},
        {name: "enabled", label: "是否啟用", type: InputType.switch, value: data?.subPage.enabled},
    ]

    return (
        <Page title={`${isNew ? "新增" : "編輯"}孫頁面`}>
            {(isNew || data?.subPage) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
            />}
        </Page>
    )
}