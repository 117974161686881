import Page from "../common/Page";
import EditView from "../component/EditView";
import {InputType} from "../common/FormRenderer";
import {PageTypeList, RoleList} from "../common/TestData";
import {useParams} from "react-router-dom";
import {
    ArticleGridLayout, useCreatePageMutation,
    useGetPageLazyQuery,
    useGetVideoLazyQuery, useRemovePageMutation,
    useUpdatePageMutation,
    useUpdateVideoMutation
} from "../generated/graphql";
import {useCategories, usePages} from "../common/Utils";
import {useCallback, useEffect} from "react";
import {omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {AdvertisementGridLayoutOptions, ArticleGridLayoutOptions} from "../common/Constant";
import {useNavigate} from "react-router";


export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isNew = id === 'new';
    const [query, {data, loading, error}] = useGetPageLazyQuery({
        variables: {id: parseInt(id as string)},
    });
    const [updatePage] = useUpdatePageMutation();
    const [createPage] = useCreatePageMutation();
    const [removePage] = useRemovePageMutation();
    useEffect(()=>{
        if (!isNew) {
            query();
        }
    },[]);
    const onSubmit = useCallback(
        async (page, { resetForm}) => {
            try {
                if (isNew) {
                    const newPage = await createPage({
                        variables: {
                            page
                        }
                    });
                    NotificationPopup.success(`新增完成`);
                    navigate(`/page/${newPage.data?.createPage.id}`);
                    window.location.reload();
                }
                else {
                    await updatePage({
                        variables: {
                            id: parseInt(id as string),
                            page: omit(page, ['id']),
                        },
                        refetchQueries: ["getPage"]
                    });
                    NotificationPopup.success(`修改完成`);
                }
            }
            catch (e) {
                NotificationPopup.error(`發生問題`);
                console.error(e);
            }
        },[data]
    );
    const onRemove = async () => {
        await removePage({
            variables: {
                id: parseInt(id as string),
            },
            refetchQueries: ["getPages"],
        });
        NotificationPopup.success(`刪除成功`);
        navigate("/page");
    }
    const columns = [
        {name: "title", label: "頁面名稱", value: data?.page.title},
        {name: "articleGridLayout", label: "頁面版型", type: InputType.select, options: ArticleGridLayoutOptions, value: data?.page.articleGridLayout},
        {name: "advertisementGridLayout", label: "廣告版型", type: InputType.select, options: AdvertisementGridLayoutOptions, value: data?.page.advertisementGridLayout},
        {name: "enabled", label: "是否啟用", type: InputType.switch, value: data?.page.enabled},
    ]

    return (
        <Page title={`${isNew ? "新增" : "編輯"}子頁面`}>
            {(isNew || data?.page) &&
            <EditView
                columns={columns}
                onSubmit={onSubmit}
                onRemove={onRemove}
            />}
        </Page>
    )
}